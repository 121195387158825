<template>
  <section>
    <div v-if="plan_change">
      <Complete 
        :plan_change="plan_change"
        :pagedata="pagedata"
        :salesdata="salesdata"
        />
    </div>
    <div v-else>
      <h2 class="is-size-4 has-text-weight-bold py-5">
        {{ chargesdata && chargesdata.page && !chargesdata.page.payment_date && pagedata['plan'] === 'standard' ? `決済を再開する` : `ページを公開する（月額利用料の決済）` }}
      </h2>
      <div class="mb-3">
        <p class="mb-1 is-size-3">{{ pagedata['business_name'] }} {{ pagedata['branch_name'] }}</p>
        <p>{{ destination_url }}/{{ pagedata['page_id'] }}</p>
      </div>

      <p class="has-text-danger">決済金額</p>
      <p class="is-size-3">月額{{ amount.toLocaleString() }}円（税込）</p>
      <p>10%対象(税抜)：{{ tax_excluded_price.toLocaleString() }}円</p>
      <p>10%対象消費税：{{ tax.toLocaleString() }}円</p>

      <!-- 決済停止されている場合(mountをしないと表示されなくなるのであえてdisplay:noneで対応) -->
      <div :class="{ noDisplay: chargesdata && chargesdata.page && !chargesdata.page.payment_date && pagedata['plan'] === 'standard' }">
        <hr />
        <p>利用可能なクレジットカード</p>
        <img src="@/assets/payment_icon/VISA.png" alt="VISA" width="10%" />
        <img src="@/assets/payment_icon/MasterCard.png" alt="MasterCard" width="10%" />
        <img src="@/assets/payment_icon/JCB.png" alt="JCB" width="10%" />
        <img src="@/assets/payment_icon/AMERICAN EXPRESS.png" alt="AMERICAN EXPRESS" width="10%" />
        <img src="@/assets/payment_icon/DinersClub.png" alt="DinersClub" width="10%" />

        <b-field label="カード番号（ハイフンなし）">
          <div class="col s12 card-element">
            <div class="error">{{ stripeValidationErrorCardnumber }}</div>
            <div id="card-number-element" class="input-value"></div>
          </div>
        </b-field>

        <b-field label="有効期限">
          <div class="col s6 card-element">
            <div class="error">{{ stripeValidationErrorExpired }}</div>
            <div id="card-expiry-element"></div>
          </div>
        </b-field>
        <div class="comment">カードに記載されている通り入力してください</div>

        <b-field label="セキュリティーコード（CVC）">
          <div class="col s6 card-element">
            <div id="card-cvc-element"></div>
          </div>
        </b-field>
        <div class="comment">セキュリティコードとは、クレジットカードの裏面に記載されている3桁の数字です。</div>
        <div class="comment">AMEXの場合は、カード表面右上にある4桁の数字です。</div>

        <p class="py-3">
          <a href="https://docs.google.com/document/d/1dDwiiCIn9qtttsSwraAFYzHQCZGsuHQhupXCSYfCF4g/edit" target="_blank" rel="noopener"
            >利用規約を見る <b-icon icon="launch" size="is-small"></b-icon
          ></a>
        </p>
      </div>

      <div v-if="chargesdata && chargesdata.page && !chargesdata.page.payment_date && pagedata['plan'] === 'standard'">
        <div class="col s12 place-order-button-block">
          <p class="mb-5">支払いを再開すると次回以降の月額利用料の決済が再開されます。</p>
          <b-button v-if="success === false" class="btn col s12 #e91e63 pink" type="is-success has-text-weight-bold" @click="repaymentButtonPressed()"> 決済を再開する </b-button>
          <b-button v-if="success === 'loading'" loading>Loading</b-button>
          <b-loading :is-full-page="isFullPage" :active.sync="isLoading"></b-loading>
        </div>
      </div>
      <div v-else>
        <div class="col s12 place-order-button-block">
          <b-button v-if="success === false" class="btn col s12 #e91e63 pink" type="is-success has-text-weight-bold" @click="placeOrderButtonPressed('page')"> 利用規約に同意の上、決済する </b-button>
          <b-button v-if="success === 'loading'" loading>Loading</b-button>
          <b-loading :is-full-page="isFullPage" :active.sync="isLoading"></b-loading>
        </div>
        <p class="help has-text-centered mt-0">1ヶ月間ごとの自動更新となります</p>
        <br />
        <p class="help has-text-centered mt-0">登録番号：T6010001124948</p>
        <p class="help has-text-centered mt-0">株式会社ILB</p>
      </div>
    </div>
  </section>
</template>

<script>
import { loadStripe } from '@stripe/stripe-js';
import Config from '../../config.js';
import Complete from '@/components/payment/Complete.vue';

export default {
  props: ['pagedata', 'salesdata', 'chargesdata'],
  components: {
    Complete,
  },
  data() {
    return {
      stripe: null,
      cardNumberElement: null,
      cardExpiryElement: null,
      cardCVCElement: null,
      stripeValidationErrorCardnumber: '',
      stripeValidationErrorExpired: '',
      success: false,
      isLoading: false,
      isFullPage: true,
      plan_change: '',
    };
  },
  async mounted() {
    this.stripe = await loadStripe(Config.PUBLIC_KEY);

    this.createAndMountFormElements();
  },
  computed: {
    amount () {
      return this.chargesdata && this.chargesdata.page ? this.chargesdata.page.price : Config.AMOUNT
    },
    destination_url () {
      return Config.DESTINATION_URL;
    },
    tax() {
      return Math.floor(this.amount - this.amount / (1 + Config.TAX_RATE));
    },
    tax_excluded_price() {
      return this.amount - this.tax;
    },
  },
  methods: {
    // stripe UIを使って決済画面elementの作成
    createAndMountFormElements() {
      const elementStyles = {
        base: {
          fontSmoothing: 'antialiased',
          '::placeholder': {
            color: '#CFD7E0',
          },
          ':-webkit-autofill': {
            color: '#e39f48',
          },
        },
        invalid: {
          color: '#E25950',

          '::placeholder': {
            color: 'red',
          },
        },
      };

      const elementClasses = {
        focus: 'focused',
        empty: 'empty',
        invalid: 'invalid',
      };

      const elements = this.stripe.elements();
      this.cardNumberElement = elements.create('cardNumber', {
        style: elementStyles,
        class: elementClasses,
      });
      this.cardNumberElement.mount('#card-number-element');
      this.cardExpiryElement = elements.create('cardExpiry', {
        style: elementStyles,
        class: elementClasses,
      });
      this.cardExpiryElement.mount('#card-expiry-element');
      this.cardCvcElement = elements.create('cardCvc', {
        style: elementStyles,
        class: elementClasses,
        placeholder: '123',
      });
      this.cardCvcElement.mount('#card-cvc-element');
      this.cardNumberElement.on('change', this.setValidationError);
      this.cardExpiryElement.on('change', this.setValidationError);
      this.cardCvcElement.on('change', this.setValidationError);
    },
    // 入力バリデーション
    setValidationError(event) {
      this.stripeValidationErrorCardnumber = event.error && event.error.code === 'invalid_number' ? event.error.message : '';

      if (event.error) {
        switch (event.error.code) {
          case 'invalid_expiry_year_past':
            this.stripeValidationErrorExpired = event.error.message;
            break;
          case 'invalid_expiry_month_past':
            this.stripeValidationErrorExpired = event.error.message;
            break;
          default:
            this.stripeValidationErrorExpired = '';
            break;
        }
      } else {
        this.stripeValidationErrorExpired = '';
      }
    },
    // 決済再開ボタン
    async repaymentButtonPressed() {
      let swal = this.$swal;
      this.success = 'loading';
      this.isLoading = true;
      // chargesデータを変更する
      try {
        await Config.CHARGES_COLLECTION.doc(this.$store.state.page_id).set(
          {
            page: {
              payment_date: this.chargesdata.page.last_payment_date.split('/')[2],
            },
          },
          { merge: true }
        );

        await swal({
          title: '決済が再開されました',
          html: this.pagedata.business_name
            + (this.pagedata.branch_name || '')
            + '<br>'
            + Config.DESTINATION_URL
            + '/'
            + this.pagedata.page_id,
          confirmButtonText: '管理画面に戻る',
          icon: 'success',
        });

        // TOPに戻る
        await this.$router.push('/').catch(() => {});
      } catch (e) {
        await swal({
          title: '決済の再開がエラーとなりました',
          html: 'しばらく立ってから再度お試しください。' + '<br>',
          confirmButtonText: '閉じる',
          icon: 'error',
        });
      }
      this.success = await false;
      this.isLoading = await false;
    },

    // 決済するボタン
    async placeOrderButtonPressed(service_name) {
      this.success = 'loading';
      this.isLoading = true;
      this.stripe.createToken(this.cardNumberElement).then((result) => {
        if (result.error) {
          this.stripeValidationError = result.error.message;
          this.success = false;
          this.isLoading = false;
        } else {
          let stripeObject = {
            amount: this.amount,
            source: result.token,
          };
          this.chargeStripe(service_name, stripeObject);
        }
      });
    },
    // stripe決済処理
    async chargeStripe(service_name, stripeObject) {
      const checkOut = Config.FIREBASE_FUNCTIONS.httpsCallable('instantPayment');

      let checkout_data = {};
      checkout_data['stripe'] = stripeObject;
      checkout_data['page_data'] = this.pagedata;
      checkout_data['service_name'] = service_name;

      // let agentRequestBtnClick = false;
      let swal = this.$swal;

      try {
        let result = await checkOut(checkout_data);
        if (result.data.status) {
          // 完了画面を表示させる
          this.plan_change = 'upgrade';

          // salesdata.page_revisionがtrue以外は代理入力表示させる
          // if (
          //   !this.salesdata || !this.salesdata.page_revision
          // ) {
          //   await swal
          //     .mixin({
          //       onOpen: () => {
          //         document
          //           .getElementById("agent-request-button")
          //           .addEventListener("click", function () {
          //             agentRequestBtnClick = true;
          //             swal.clickConfirm();
          //           }),
          //           document
          //             .getElementById("close-modal")
          //             .addEventListener("click", function () {
          //               swal.clickConfirm();
          //             });
          //       },
          //     })
          //     .fire({
          //       title: "決済が完了し、ページが公開されました",
          //       html:
          //         this.$store.state.admin_data.service_name[service_name].name +
          //         "<br>月額" +
          //         this.amount.toLocaleString() +
          //         "円（税込）<br><br>" +
          //         "10%対象(税抜)：" + this.tax_excluded_price.toLocaleString()
          //         + "円<br>" +
          //         "10％対象消費税：" + this.tax.toLocaleString()
          //         + "円（税込）<br><br>" +
          //         '<div class="block">' +
          //         '<div style="margin-bottom: 10px;padding: 10px;background-color:#effaf5;color: #257953;">' +
          //         '<b><div class="tag is-danger">' +
          //         '<span class="">無料</span></div>' +
          //         '<span class="has-text-grey-dark">&nbsp;ページの代理入力を依頼する</span></b>' +
          //         '<div class="help" style="margin-bottom: 10px;">問合せ数を増やすため、ページのデータ入力・修正を無料で代行します。</div>' +
          //         '<button id="agent-request-button" type="button" class="button button is-outlined is-fullwidth is-link">' +
          //         "<span>詳細を見る</span></button>" +
          //         "</div>" +
          //         "</div>" +
          //         "<br>" +
          //         '<p class="">' +
          //         '<a id="close-modal" href="#">管理画面に戻る</a>' +
          //         "</p>",
          //       showConfirmButton: false,
          //       allowOutsideClick: false,
          //     });
          // } else {
          //   await swal.fire({
          //     icon: "success",
          //     title: "決済が完了し、ページが公開されました",
          //     html:
          //       this.$store.state.admin_data.service_name[service_name].name +
          //       "<br>月額" +
          //       this.amount.toLocaleString() +
          //       "円（税込）",
          //     confirmButtonText: "管理画面に戻る",
          //   });
          // }

          this.success = await false;
          this.isLoading = await false;

          // // 代理入力を依頼する
          // if (agentRequestBtnClick) {
          //   await new Promise((s) => setTimeout(s, 500));
          //   this.changeModal("changeModalAgentRequest");
          //   return;
          // }
        } else {
          await swal({
            title: '決済がエラーとなりました',
            html: result.data.error.code + '<br>' + result.data.error.message + '<br>',
            confirmButtonText: '管理画面に戻る',
            icon: 'error',
          });
          this.success = await false;
          this.isLoading = await false;
        }
      } catch (e) {
        await swal({
          title: '決済がエラーとなりました',
          text: '管理者に連絡してください:' + e.message,
          confirmButtonText: '管理画面に戻る',
          icon: 'error',
        });
        this.success = await false;
        this.isLoading = await false;
      }
    },
  },
};
</script>

<style scoped>
.payment-form .comment {
  font-size: 0.8rem;
  margin-bottom: 10px;
}

.payment-form .error {
  color: red;
}

.payment-form {
  max-width: 400px;
  margin: 20px auto;
  border: 2px solid #ececec;
  padding: 12px;
  margin-bottom: 8px;
}

.payment-form h5 {
  margin: 5px;
  padding: 0px;
  text-align: center;
  font-size: 1.2rem;
}

.payment-form h6 {
  margin-bottom: 5px;
  padding: 0px;
  text-align: center;
  font-size: 1.5rem;
}

.card-element {
  margin-top: 10px;
}

#card-number-element,
#card-expiry-element,
#card-cvc-element {
  background: white;
  padding: 5px;
  border: 2px solid #ececec;
  height: 30px;
}

.place-order-button-block {
  margin: 5px 0;
  text-align: center;
}

button {
  background-color: #23d160;
  color: white;
  border-radius: 4px;
  font-size: 20px;
  /* font-weight: 600; */
  border: 0;
  box-shadow: none;
  padding: 8px 24px;
}

.notification.is-link.is-light {
  padding: 1ex;
}

.notification.is-danger.is-light {
  padding: 1ex;
}
.noDisplay {
  display: none;
}
</style>
