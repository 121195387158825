<template>
<section class="section container">

  <div class="columns">
    <div class="column is-3">
      <Menu @getdoc='getPageId' @gettitle='getPageTitle'/>
    </div>

    <div class="column">

      <div v-if='doc'>

        <h1 class="is-size-4 has-text-weight-bold py-1">チラシの作成</h1>

        <p class="pt-3">下記より作成したいチラシを選択してください。
          <span class="is-hidden-desktop">
            右にスワイプできます。
          </span>
        </p>
        <hr>

        <b-tabs v-model='activeTab'>


          <b-tab-item label="サービス宣伝用">

              <img src="https://storage.googleapis.com/admin-ilb.appspot.com/ilbnet-jp/flyer_service.jpg" alt="flyer画像" width="50%">
              <p>
                <a href="https://drive.google.com/file/d/16UPgFiFfhF-8Y0kE31hIak0kZ1El0ufs/view?usp=sharing" target="_blank">「サービス宣伝用チラシ」の制作事例を見る&nbsp;<b-icon icon="launch" size="is-small"></b-icon></a>
              </p>
              <br>
              <p>
                下記のボタンをクリックすると<br>
                サービス宣伝用チラシが作成されます。
              </p>
              <p>
                <a :href="'https://app.ilb.net/flyer/jp/page_service.html?id=' + doc['page_id']" target="_blank" class="button is-info">チラシを作成する</a>
              </p>
              <br>
              <p>
                <span class="has-text-danger">必ずデータ（PDF）をダウンロードして印刷してください。</span><br>
                <a href="https://docs.google.com/document/d/1o7xgFY5UBab6l-eliWiScbi7lN1ffO2nUArsiwNkNYo/" target="_blank">チラシ印刷時の注意点を見る&nbsp;<b-icon icon="launch" size="is-small"></b-icon></a><br>
                <a href="https://docs.google.com/document/d/12qHnt33l4s02wKxP_HDcIiK5bTQZV96cQ1HlPZxfGps/edit?usp=sharing " target="_blank">チラシ印刷を業者に依頼する場合の注意点を見る&nbsp;<b-icon icon="launch" size="is-small"></b-icon></a>
              </p>
              <hr>
              <div class="py-3">
                <p class="pb-1">
                  <span class="has-text-weight-bold	pb-2">サービス宣伝用チラシの掲載内容</span>
                </p>
                <p class="pb-3">
                  <a href="https://docs.google.com/document/d/1euXNJqYTsza6xYdMlD0uSCAHTpqpf4Rcp1Q_YKagSOk/edit?usp=sharing" target="_blank">サービス宣伝用チラシの作成方法と活用方法を見る&nbsp;<b-icon icon="launch" size="is-small"></b-icon></a>
                </p>
                <br>
                <div>
                  各項目の入力状況は、下記よりチェックできます。
                </div>

                <div class="closing-rate-details-check-item">
                  ※入力済みの項目は、チェック「<img src="@/assets/check-square.svg" style="opacity: 0.2;" />」と「<span style="text-decoration: line-through;">打ち消し線</span>」で表示されています。<br />
                  例）<br />
                  <img src="@/assets/square.svg" />未入力の項目<br />
                  <img src="@/assets/check-square.svg" style="opacity: 0.2;" /><span class="item-entered">入力済みの項目</span>
                </div>
                <hr>

                <div v-for="item in getIncreaseClosingRateItems" :key="item.title">
                  <span class="has-text-weight-bold">{{ item.title }}</span><br>

                  <div class="check-item closing-rate-details-check-item">
                    <template v-for="(targetItem, index) in item.targetItems">
                      <div v-if="checkPageDataItem(targetItem)" :key="index">
                        <img src="@/assets/check-square.svg" style="opacity: 0.2;" />
                        <span class="item-entered">{{ getPageDataItemName(targetItem) }}</span>
                      </div>
                      <div v-else :key="index">
                        <img src="@/assets/square.svg" />
                        <span>{{ getPageDataItemName(targetItem) }}</span>
                      </div>
                    </template>
                    <a class="button is-link is-outlined mt-1" @click="$router.push('/?active_tab=' + item.tabNo)">{{ item.label }}</a>
                  </div>
                </div>
              </div>
            </b-tab-item>

          <b-tab-item label="オファー宣伝用">

              <img src="https://storage.googleapis.com/admin-ilb.appspot.com/ilbnet-jp/flyer_offer.jpg" alt="flyer画像" width="50%">
              <p>
                <a href="https://drive.google.com/file/d/1jY4I0dzUNa-C7cCmM2sSAZG0i3N9j1Sr/view?usp=sharing" target="_blank">「オファー宣伝用チラシ」の制作事例を見る&nbsp;<b-icon icon="launch" size="is-small"></b-icon></a>
              </p>
              <br>
              <p>
                下記のボタンをクリックすると<br>
                オファー宣伝用チラシが作成されます。
              </p>
              <p>
                <a :href="'https://app.ilb.net/flyer/jp/page_offer.html?id=' + doc['page_id']" target="_blank" class="button is-info">チラシを作成する</a>
              </p>
              <br>
              <p>
                <span class="has-text-danger">必ずデータ（PDF）をダウンロードして印刷してください。</span><br>
                <a href="https://docs.google.com/document/d/1o7xgFY5UBab6l-eliWiScbi7lN1ffO2nUArsiwNkNYo/" target="_blank">チラシ印刷時の注意点を見る&nbsp;<b-icon icon="launch" size="is-small"></b-icon></a><br>
                <a href="https://docs.google.com/document/d/12qHnt33l4s02wKxP_HDcIiK5bTQZV96cQ1HlPZxfGps/" target="_blank">チラシ印刷を業者に依頼する場合の注意点を見る&nbsp;<b-icon icon="launch" size="is-small"></b-icon></a>
              </p>
              <hr>
              <div class="py-3">
                <p class="pb-1">
                  <span class="has-text-weight-bold	pb-2">オファー宣伝用チラシの掲載内容</span>
                </p>
                <p class="pb-3">
                  <a href="https://docs.google.com/document/d/1kHsqBazgKxjqQkgrXZkZpGfNg6Nwj5DQtCclbFh9rho/edit?usp=sharing" target="_blank">オファー宣伝用チラシの作成方法と活用方法を見る&nbsp;<b-icon icon="launch" size="is-small"></b-icon></a>
                </p>
                <br>
                <div>
                  各項目の入力状況は、下記よりチェックできます。
                </div>

                <div class="closing-rate-details-check-item">
                  ※入力済みの項目は、チェック「<img src="@/assets/check-square.svg" style="opacity: 0.2;" />」と「<span style="text-decoration: line-through;">打ち消し線</span>」で表示されています。<br />
                  例）<br />
                  <img src="@/assets/square.svg" />未入力の項目<br />
                  <img src="@/assets/check-square.svg" style="opacity: 0.2;" /><span class="item-entered">入力済みの項目</span>
                </div>
                <hr>

                <div v-for="item in getPageOfferRequiredItems" :key="item.title">
                  <span class="has-text-weight-bold">{{ item.title }}</span><br>

                  <div class="check-item closing-rate-details-check-item">
                    <template v-for="(targetItem, index) in item.targetItems">
                      <div v-if="checkPageDataItem(targetItem)" :key="index">
                        <img src="@/assets/check-square.svg" style="opacity: 0.2;" />
                        <span class="item-entered">{{ getPageDataItemName(targetItem) }}</span>
                      </div>
                      <div v-else :key="index">
                        <img src="@/assets/square.svg" />
                        <span>{{ getPageDataItemName(targetItem) }}</span>
                      </div>
                    </template>
                    <a class="button is-link is-outlined mt-1" @click="$router.push('/?active_tab=' + item.tabNo)">{{ item.label }}</a>
                  </div>
                </div>
              </div>
            </b-tab-item>

            <b-tab-item label="LINE友だち募集用">

              <img src="https://storage.googleapis.com/admin-ilb.appspot.com/ilbnet-jp/flyer_line.jpg" alt="line_flyer画像" width="50%">
              <p class="">
                <a href="https://drive.google.com/file/d/1pXnOPrsFv6IjaRISu0j-oYtHRbnYTh5j/view?usp=sharing" target="_blank">「LINE友だち募集用チラシ」の制作事例を見る&nbsp;<b-icon icon="launch" size="is-small"></b-icon></a>
              </p>
              <br>
              <p>
                下記のボタンをクリックすると<br>
                LINE友だち募集用チラシを作成できます。
              </p>
              <p>
                <a :href="'https://app.ilb.net/flyer/jp/line.html?id=' + doc['page_id']" target="_blank" class="button is-info">チラシを作成する</a>
              </p>
              <br>
              <p class="pb-3">
                <span class="has-text-danger">必ずデータ（PDF）をダウンロードして印刷してください。</span><br>
                <a href="https://docs.google.com/document/d/1o7xgFY5UBab6l-eliWiScbi7lN1ffO2nUArsiwNkNYo/" target="_blank">チラシ印刷時の注意点を見る&nbsp;<b-icon icon="launch" size="is-small"></b-icon></a><br>
                <a href="https://docs.google.com/document/d/12qHnt33l4s02wKxP_HDcIiK5bTQZV96cQ1HlPZxfGps/" target="_blank">チラシ印刷を業者に依頼する場合の注意点を見る&nbsp;<b-icon icon="launch" size="is-small"></b-icon></a>
              </p>
              <hr>
              <div class="py-3">
                <p class="pb-1">
                  <span class="has-text-weight-bold	">LINE友だち募集用チラシの掲載内容</span>
                </p>
                <p class="pb-3">
                  <a href="https://docs.google.com/document/d/1I2U6bNqMgZkwv_kIZwRKj3MJi0vl_WU7trmS4trQJdA/edit?usp=sharing" target="_blank">LINE友だち募集用チラシの作成方法と活用方法を見る&nbsp;<b-icon icon="launch" size="is-small"></b-icon></a>
                </p>
                <br>
                <div>
                  各項目の入力状況は、下記よりチェックできます。
                </div>

                <div class="closing-rate-details-check-item">
                  ※入力済みの項目は、チェック「<img src="@/assets/check-square.svg" style="opacity: 0.2;" />」と「<span style="text-decoration: line-through;">打ち消し線</span>」で表示されています。<br />
                  例）<br />
                  <img src="@/assets/square.svg" />未入力の項目<br />
                  <img src="@/assets/check-square.svg" style="opacity: 0.2;" /><span class="item-entered">入力済みの項目</span>
                </div>
                <hr>

                <div v-for="item in getLineRequiredItems" :key="item.title">
                  <span class="has-text-weight-bold">{{ item.title }}</span><br>

                  <div class="check-item closing-rate-details-check-item">
                    <template v-for="(targetItem, index) in item.targetItems">
                      <div v-if="checkPageDataItem(targetItem)" :key="index">
                        <img src="@/assets/check-square.svg" style="opacity: 0.2;" />
                        <span class="item-entered">{{ getPageDataItemName(targetItem) }}</span>
                      </div>
                      <div v-else :key="index">
                        <img src="@/assets/square.svg" />
                        <span>{{ getPageDataItemName(targetItem) }}</span>
                      </div>
                    </template>
                    <a class="button is-link is-outlined mt-1" @click="$router.push('/?active_tab=' + item.tabNo)">{{ item.label }}</a>
                  </div>
                </div>
              </div>
            </b-tab-item>

            <b-tab-item label="クチコミ募集用">
              <img src="https://storage.googleapis.com/admin-ilb.appspot.com/ilbnet-jp/flyer_gbp.jpg" alt="gbp_flyer_画像" width="50%">
              <p class="">
                <a href="https://drive.google.com/file/d/1_ULLOuMKBCW_817ucpLSsUiyXE2YdZx3/view?usp=sharing " target="_blank">「クチコミ募集用チラシ」の制作事例を見る&nbsp;<b-icon icon="launch" size="is-small"></b-icon></a>
              </p>
              <br>
              <p>
                下記のボタンをクリックすると<br>
                クチコミ募集用チラシが作成されます。<br>
              </p>
              <p>
                <a :href="'https://app.ilb.net/flyer/jp/gbp.html?id=' + doc['page_id']" target="_blank" class="button is-info">チラシを作成する</a>
              </p>
              <br>
              <p class="pb-3">
                <span class="has-text-danger">必ずデータ（PDF）をダウンロードして印刷してください。</span><br>
                <a href="https://docs.google.com/document/d/1o7xgFY5UBab6l-eliWiScbi7lN1ffO2nUArsiwNkNYo/" target="_blank">チラシ印刷時の注意点を見る&nbsp;<b-icon icon="launch" size="is-small"></b-icon></a><br>
                <a href="https://docs.google.com/document/d/12qHnt33l4s02wKxP_HDcIiK5bTQZV96cQ1HlPZxfGps/" target="_blank">チラシ印刷を業者に依頼する場合の注意点を見る&nbsp;<b-icon icon="launch" size="is-small"></b-icon></a>
              </p>
              <hr>
              <div class="py-3">
                <p class="pb-1">
                  <span class="has-text-weight-bold	">クチコミ募集用チラシの掲載内容</span>
                </p>
                <p class="pb-3">
                  <a href="https://docs.google.com/document/d/1zTqCy-3fbloQRsLv1ETyiFxtgzP_WfN2azgVIaxieHA/edit?usp=sharing" target="_blank">クチコミ募集用チラシの作成方法と活用方法を見る&nbsp;<b-icon icon="launch" size="is-small"></b-icon></a>
                </p>
                <br>
                <div class="closing-rate-details-check-item">
                  ※入力済みの項目は、チェック「<img src="@/assets/check-square.svg" style="opacity: 0.2;" />」と「<span style="text-decoration: line-through;">打ち消し線</span>」で表示されています。<br />
                  例）<br />
                  <img src="@/assets/square.svg" />未入力の項目<br />
                  <img src="@/assets/check-square.svg" style="opacity: 0.2;" /><span class="item-entered">入力済みの項目</span>
                </div>

                <hr>

                <div v-for="item in getVoiceOfCustomerRequiredItems" :key="item.title">
                  <span class="has-text-weight-bold">{{ item.title }}</span><br>

                  <div class="check-item closing-rate-details-check-item">
                    <template v-for="(targetItem, index) in item.targetItems">
                      <div v-if="checkPageDataItem(targetItem)" :key="index">
                        <img src="@/assets/check-square.svg" style="opacity: 0.2;" />
                        <span class="item-entered">{{ getPageDataItemName(targetItem) }}</span>
                      </div>
                      <div v-else :key="index">
                        <img src="@/assets/square.svg" />
                        <span>{{ getPageDataItemName(targetItem) }}</span>
                      </div>
                    </template>
                    <a class="button is-link is-outlined mt-1" @click="$router.push('/?active_tab=' + item.tabNo)">{{ item.label }}</a>
                  </div>
                </div>
              </div>
            </b-tab-item>

            
        </b-tabs>
      </div>
    </div>
  </div>

</section>
</template>

<script>
import 'bulma/css/bulma.css'
import Menu from '@/components/Menu.vue'

export default {
  components: {
    Menu,
  },
  data () {
    return {
      activeTab: 0,
      doc: {},
      title: {}
    }
  },
  computed: {
    opening_check : function() {
      if (!this.doc['opening']) return false

      for (const [key, value] of Object.entries(this.doc['opening'])) {
        console.log(`${key}: ${value}`)
        // 1件でもopen_dayがtrueならメニュー表示
        if( value.open_day === true) {
          return true
        }
      }
      return false
    },
    getIncreaseClosingRateItems() {
      const items = []
      items.push(
        {
          id: 'required',
          label: '必須項目を入力・修正する',
          title: '１．必須項目',
          targetItems: [
            'business_name',
            'phone',
            'region',
            'city',
            'street_address',
            'category',
            'catchphrase',
          ],
          tabNo: '0',
        }
      )
      items.push(
        {
          id: 'basicinfo',
          label: '基本情報を入力・修正する',
          title: '２．基本情報',
          targetItems: [
            'service_keywords_0',
            'service_keywords_1',
            'service_keywords_2',
            'service_keywords_3',
            'service_keywords_4',
            'service_keywords_5',
            'service_keywords_6',
            'service_keywords_7',
            'service_keywords_8',
            'service_keywords_9',
            'station',
            'access',
            'opening',
          ],
          tabNo: '1',
        }
      )
      items.push(
        {
          id: 'service',
          label: '提供サービスを入力・修正する',
          title: '３．提供サービス',
          targetItems: [
            'service0_title',
            'service0_tag',
            'service0_detail',
            'service0_price',
            'service0_image',
            'service1_title',
            'service1_tag',
            'service1_detail',
            'service1_price',
            'service1_image',
            'service2_title',
            'service2_tag',
            'service2_detail',
            'service2_price',
            'service2_image',
          ],
          tabNo: '4',
        }
      )
      items.push(
        {
          id: 'feature',
          label: '特長を入力・修正する',
          title: '４．特長',
          targetItems: [
            'feature0_title',
            'feature0_image',
            'feature1_title',
            'feature1_image',
            'feature2_title',
            'feature2_image',
            'feature3_title',
            'feature3_image',
            'feature4_title',
            'feature4_image',
          ],
          tabNo: '5',
        }
      )
      return items
    },
    getPageOfferRequiredItems() {
      const items = []
      items.push(
        {
          id: 'required',
          label: '必須項目を入力・修正する',
          title: '１．必須項目',
          targetItems: [
            'business_name',
            'phone',
            'region',
            'city',
            'street_address',
            'category',
            'catchphrase'
          ],
          tabNo: '0',
        }
      )
      items.push(
        {
          id: 'basicinfo',
          label: '基本情報を入力・修正する',
          title: '２．基本情報',
          targetItems: [
            'service_keywords_0',
            'service_keywords_1',
            'service_keywords_2',
            'service_keywords_3',
            'service_keywords_4',
            'service_keywords_5',
            'service_keywords_6',
            'service_keywords_7',
            'service_keywords_8',
            'service_keywords_9',
            'station',
            'access',
            'opening',
          ],
          tabNo: '1',
        }
      )
      items.push(
        {
          id: 'offer',
          label: 'オファーを入力・修正する',
          title: '３．オファー',
          targetItems: [
            'offer_catchphrase',
            'offer_limited',
            'offer_title',
            'offer_price',
            'offer_image',
            'offer_detail',
          ],
          tabNo: '2',
        }
      )
      items.push(
        {
          id: 'staff',
          label: 'スタッフを入力・修正する',
          title: '４．スタッフ',
          targetItems: [
            'staff_name',
            'staff_image',
            'staff_message',
          ],
          tabNo: '6',
        }
      )
      items.push(
        {
          id: 'feature',
          label: '特長を入力・修正する',
          title: '5．特長',
          targetItems: [
            'feature0_title',
            'feature0_image',
            'feature1_title',
            'feature1_image',
            'feature2_title',
            'feature2_image',
            'feature3_title',
            'feature3_image',
            'feature4_title',
            'feature4_image',
          ],
          tabNo: '5',
        }
      )
      return items
      },
      getLineRequiredItems() {
        const items = []
        items.push(
          {
            id: 'required',
            label: '必須項目を入力・修正する',
            title: '１．必須項目',
            targetItems: [
              'business_name',
              'branch_name',
            ],
            tabNo: '0',
          }
        )
        items.push(
          {
            id: 'line',
            label: 'LINEを入力・修正する',
            title: '２．LINE',
            targetItems: [
              'line_id',
              'line_title',
              'line_image',
              'line_detail',
            ],
            tabNo: '3',
          }
        )
        return items
      },
      getVoiceOfCustomerRequiredItems() {
        const items = []
        items.push(
          {
            id: 'required',
            label: '必須項目を入力・修正する',
            title: '必須項目',
            targetItems: [
              'place_id',
              'business_name',
              'branch_name'
            ],
            tabNo: '0',
          }
        )
        return items

    }
  },
  methods: {
    getPageId (value) {
      this.doc = value
    },
    getPageTitle (value) {
      this.title = value
    },
    checkPageDataItem(item) {
      if (
        this.doc[item] ||
        this.doc['feature'] ||
        this.doc['service'] ||
        this.doc['service_keywords']
      ) {
        if (item === 'opening') {
          return Object.keys(this.doc['opening']).find(key => this.doc['opening'][key].open_day)
        } else if (
          item.match(/feature[0-9]/) ||
          item.match(/service[0-9]/)
        ) {
          const words = item.split('_')
          const firstWord = words[0].replace(/[0-9]/g, '')
          const firstNo = Number(words[0].replace(/[^0-9]/g, ''))
          return this.doc[firstWord][firstNo][words[1]]
        } else if (item.match(/service_keywords/)) {
          const no = Number(item.replace(/[^0-9]/g, ''))
          return this.doc['service_keywords'][no]
        } else if (Array.isArray(this.doc[item])) {
          return Object.keys(this.doc[item]).length
        } else {
          return this.doc[item]
        }
      }
    },
    // checkAllPageDataItem(items) {
    //   for (const item of items) {
    //     if (!this.checkPageDataItem(item)) {
    //       return false
    //     }
    //   }
    //   return true
    // },
    getPageDataItemName(item) {
      if (
        item.match(/feature[0-9]/) ||
        item.match(/service[0-9]/)
      ) {
        const words = item.split('_')
        const firstWord = words[0].replace(/[0-9]/g, '')
        const firstNo = Number(words[0].replace(/[^0-9]/g, ''))
        if (this.title[firstWord]) {
          return this.title[firstWord][firstNo][words[1]]
        }
      } else if (item.match(/service_keywords/)) {
        const no = Number(item.replace(/[^0-9]/g, ''))
        if (this.title['service_keywords']) {
          return this.title['service_keywords'][no]
        }
      } else {
        return this.title[item]
      }
    }
  },
  mounted() {
    // queryにactive_tabが設定されてた場合、初期表示のタブとして設定
    // 設定後queryは削除する
    if (this.$route.query.active_tab) {
      this.activeTab = Number(this.$route.query.active_tab)
      let query = Object.assign({}, this.$route.query)
      delete query['active_tab']
      this.$router.push({query: query})
      window.scrollTo(0, 0);
    }
  },
}
</script>

<style>
input[readonly='readonly']{
background-color:#c0c0c0;
color:#666;
}
.closing-rate-details-check-item img {
  border: none;
  width: 20px;
  height: 20px;
  padding: 0px;
  margin-right: 5px;
  vertical-align: text-top;
}
.check-item{
  margin-bottom: 20px;
}

</style>