<template>
  <form @submit.prevent="validateForm">
    <section class="row payment-form">
        <h5>
          広告の停止・再開
        </h5>
        <p class="has-text-centered service-title">広告の着地先URL</p>
        <p class="has-text-centered service-title">
          {{ pagedata['business_name'] }} {{ pagedata['branch_name'] }}<br>
          <a :href="'https://jp.ilb.net/' + pagedata['page_id'] + '/'" target="_blank">
              https://jp.ilb.net/{{ pagedata['page_id'] }}/
          </a>
        </p>

        <b>広告の種類</b>
        <p class="is-size-7">広告の種類を選択してください</p>
        <p class="service-type">
          <b-radio
            v-if="gsDisable"
            v-model="serviceName"
            name="希望の広告"
            native-value="gs"
            disabled
            >
              Google広告（検索）<span v-if="gsDeliveryOff" style="color:#000;">：停止中</span>
          </b-radio>
          <b-radio
            v-else
            v-model="serviceName"
            name="希望の広告"
            native-value="gs"
            v-validate="'required'"
            >
              Google広告（検索）<span v-if="gsDeliveryOff" style="color:#000;">：停止中</span>
            </b-radio>
          <b-radio
            v-if="gdDisable"
            v-model="serviceName"
            name="希望の広告"
            native-value="gd"
            disabled
          >
            Google広告（ディスプレイ）<span v-if="gdDeliveryOff" style="color:#000;">：停止中</span>
          </b-radio>
          <b-radio
            v-else
            v-model="serviceName"
            name="希望の広告"
            native-value="gd"
            v-validate="'required'"
          >
              Google広告（ディスプレイ）<span v-if="gdDeliveryOff" style="color:#000;">：停止中</span>
          </b-radio>
        <span class="help is-danger" v-show="errors.has('広告の種類')">{{ errors.first('広告の種類') }}</span>
        </p>

        <p v-if="budget"><b>現在の広告費</b>：{{budget / 10000 }}万円</p>
        <p v-if="paymentDate"><b>現在の決済日</b>：{{paymentDate}}日</p>
        <br>

        <div v-if="serviceName">
          <p>{{ message }}</p>
          <br>
          <div v-if="success === false && type === 'restart'">
            <p><b>広告費の残金</b>：{{remainingBudget}}円</p>
            <br>
            <p>広告費の残金がある場合は、広告の運用が即時に再開されます。</p>
          </div>
          <div class="col s12 place-order-button-block">
            <b-button
              v-if="success === false && type === 'stop'"
              class="btn col s12 #e91e63 pink"
              type="is-danger"
              @click="validateForm"
              :disabled="errors.any() || !isFormCompleted"
              >
                {{ buttonMessage }}
            </b-button>
            <b-button
              v-if="success === false && type === 'restart'"
              class="btn col s12 #e91e63 pink"
              type="is-info"
              @click="validateForm"
              :disabled="errors.any() || !isFormCompleted"
              >
                {{ buttonMessage }}
            </b-button>
            <b-button v-if="success === 'loading'" loading>Loading</b-button>
            <b-button v-if='success === true' class="btn col s12 #e91e63 pink" @click="modalClose">閉じる</b-button>
            <b-loading :is-full-page="isFullPage" :active.sync="isLoading"></b-loading>
          </div>
        </div>
    </section>
  </form>
</template>

<script>
import Config from '../../config.js'
import moment from 'moment'
import 'moment-timezone'

export default {
    props: ['pagedata', 'adsdata', 'chargesdata'],
    computed: {
      remainingBudget() {
        return this.adsdata[this.serviceName]
          ? this.adsdata[this.serviceName].total_amount - this.adsdata[this.serviceName].total_cost >= 0
          ? this.adsdata[this.serviceName].total_amount - this.adsdata[this.serviceName].total_cost
          : 0
          : null
      },
      paymentDate () {
        return this.chargesdata[this.serviceName] ? this.chargesdata[this.serviceName].payment_date : null
      },
      budget() {
        return this.adsdata[this.serviceName] ? this.adsdata[this.serviceName].budget : null
      },
      gsDisable() {
        if (this.chargesdata.gs && this.chargesdata.gs.payment_date) {
          return false
        }
        return true
      },
      gsDeliveryOff() {
        if (this.adsdata.gs && this.adsdata.gs.delivery === 'off') {
          return true
        }
        return false
      },
      gdDisable() {
        if (this.chargesdata.gd && this.chargesdata.gd.payment_date) {
          return false
        }
        return true
      },
      gdDeliveryOff() {
        if (this.adsdata.gd && this.adsdata.gd.delivery === 'off') {
          return true
        }
        return false
      },
      type() {
        if (this.adsdata[this.serviceName]) {
          if(this.adsdata[this.serviceName].delivery === 'on') {
            return 'stop';
          } else { // suspend
            return 'restart'
          }
        }
        return 'stop';
      },
      buttonMessage() {
        if (this.adsdata[this.serviceName]) {
          if(this.adsdata[this.serviceName].delivery === 'on') {
            return '広告を停止する';
          } else {
            return '広告を再開する'
          }
        }
        return '広告を停止する';
      },
      message () {
        if (this.adsdata[this.serviceName]) {
          if(this.adsdata[this.serviceName].delivery === 'on') {
            return '「広告を停止する」ボタンをクリックすると、広告の運用を停止し、広告費の決済も停止します。';
          } else {
            return '「広告を再開する」ボタンをクリックすると、広告の運用を再開します。'
          }
        }
        return '「広告を停止する」ボタンをクリックすると、広告の運用を停止し、広告費の決済も停止します。';
      },
      isFormCompleted: function() {
        if (
          !this.serviceName
        ) {
          return false
        }
        return true
      },
    },
    data() {
        return {
            serviceName: null,
            success:false,
            isLoading: false,
            isFullPage: true,
        }
    },
    methods: {
      async buttonPressed() {
        // loadingのため設定
        this.success = 'loading'
        this.isLoading = true

        let operation = await this.getOperation() // stop or restart or spent
        let upsert = await this.getUpsert(operation) // collection更新用データ
        let swalParam = await this.getSwalParam(operation) // swal用のパラメーター
        let template_id = await this.getTemplateID(operation) // テンプレートID

        try {
          let data = {
            template_id : template_id,
            to : this.pagedata.email || this.$store.state.user.email,
            url :Config.DESTINATION_URL + '/' + this.$store.state.page_id + '/',
            admin_url: Config.MANAGE_URL,
            page_id: this.$store.state.page_id,
            service_name : this.$store.state.admin_data.service_name[this.serviceName].name,
            budget : this.budget.toLocaleString(),
            business_name: this.pagedata.business_name,
            branch_name: this.pagedata.branch_name || '',
            category: this.pagedata.category || '',
            payment_date:this.chargesdata[this.serviceName].payment_date,
            email_auth : this.$store.state.user.email,
            status: 'sending',
            date: moment().tz("Asia/Tokyo").format("YYYY-MM-DD HH:mm:ss"),
            name: this.$store.state.admin_data.service_name[this.serviceName].name,
            short_name: this.$store.state.admin_data.service_name[this.serviceName].short_name,
            system_name: this.$store.state.admin_data.service_name[this.serviceName].system_name,
            country_code: this.$store.state.admin_data.service_name.url.country_code,
            tld: this.$store.state.admin_data.service_name.url.tld,
          }
          await Config.SENDMAIL_TARGET_COLLECTION.add(data);

          // ads_dataの更新
          await Config.ADS_COLLECTION.doc(this.$store.state.page_id).set({[this.serviceName] : upsert }, { merge: true });

          // 確認用のswal
          let result = await this.$swal(swalParam);
          if (result.isConfirmed) {
            if (operation === 'spent') {
              // 決済日変更のモーダル表示
              await this.changeModal('changeModalAdBudgetChange')
            }
            await this.$emit('modalclose')
          }
        } catch (e){
          await this.$swal({
              title: '広告の' + (operation === 'stop' ? '停止' : '再開') + 'が失敗しました。',
              text:'管理者に連絡してください:' + e.message,
              confirmButtonText: '管理画面に戻る',
              icon:'error',
          })
          await this.$emit('modalclose')
        }
        this.success = true
        this.isLoading = false
      },
      getOperation() {
        if(this.adsdata[this.serviceName].delivery === 'on') {
          return 'stop';
        } else {
          if(this.adsdata[this.serviceName].status === 'spent') {
            return 'spent';
          } else {
            return 'restart';
          }
        }
      },
      getUpsert(operation) {
        let upsert
        switch (operation) {
          case 'stop':
            upsert = {
                delivery : 'off',
                delivery_change_date : moment().tz("Asia/Tokyo").format("YYYY-MM-DD HH:mm:ss"),
              }
            break;

          case 'restart':
          case 'spent':
            upsert = {
              delivery : 'on',
              delivery_change_date : moment().tz("Asia/Tokyo").format("YYYY-MM-DD HH:mm:ss"),
            }
            break;
        }
        return upsert
      },
      getSwalParam(operation) {
        let swalParam
        switch (operation) {
          case 'stop':
            swalParam = {
              title : '広告の停止を承りました',
              html : '停止には１時間程度かかります<br><br>' +
                this.$store.state.admin_data.service_name[this.serviceName].name,
              icon : 'success',
              confirmButtonText : '管理画面に戻る'
            }
            break;
          case 'restart':
            swalParam = {
              title : '広告の再開を承りました',
              html : '再開には１時間程度かかります<br><br>' +
                this.$store.state.admin_data.service_name[this.serviceName].name,
              icon : 'success',
              confirmButtonText : '管理画面に戻る'
            }
            break;
          case 'spent':
            swalParam = {
              title : '広告費の残金がないため<br>広告を即時に再開できません',
              html : '決済日よりも前に広告を再開したい場合は<br>決済日を変更してください'
                + '<br><br>'
                + this.$store.state.admin_data.service_name[this.serviceName].name
                + '<br>'
                + '決済日：' + this.chargesdata[this.serviceName].payment_date,
              icon : 'info',
              confirmButtonText : '決済日を変更する'
            }
            break;
        }
        return swalParam
      },
      getTemplateID (operation) {
        let templateID
        switch (operation) {
          case 'stop':
            templateID = 'AD_STOP_SUCCESS'
            break;

          case 'spent':
            templateID = 'AD_RESTART_STATUS_SPENT_SUCCESS'
            break;
  
          case 'restart':
            templateID = 'AD_RESTART_SUCCESS'
            break;
        }
        return templateID
      },
      modalClose() {
          this.$emit('modalclose')
      },
      changeModal(type){
        this.$emit(type);
      },
      validateForm() {
        this.$validator.validateAll().then((result) => {
          if (result) {
            this.buttonPressed();
            return;
          }
        });
      }
    },
}
</script>

<style scoped>
.service-title{
  margin-bottom:6px;
}
.service-type {
  margin-bottom:8px;
}
.service-price {
  margin-top:8px;
}
.price-box {
  margin-top: 10px;
  margin-bottom: 10px;
  padding-top: 10px;
  padding-bottom: 10px;
}
.payment-form .comment {
  font-size:0.8rem;
  margin-bottom:10px;

}
.payment-form .error {
   color:red;

}
.payment-form {
    max-width: 400px;
    margin: 20px auto;
    border: 2px solid #ececec;
    padding: 12px;
    margin-bottom: 8px;
}
.payment-form h5 {
    margin: 5px;
    padding: 0px;
    text-align:center;
    font-size: 1.2rem;
}
.payment-form h6 {
    margin-bottom: 5px;
    padding: 0px;
    text-align:center;
    font-size: 1.5rem;
}
.card-element {
    margin-top: 10px;
}

.place-order-button-block {
    margin: 5px 0;
    text-align:center;
}

button {
  background-color: #23D160;
  color: white;
  border-radius: 4px;
  font-size: 20px;
  /* font-weight: 600; */
  border: 0;
  box-shadow: none;
  padding: 8px 24px;
}
</style>
