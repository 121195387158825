import { render, staticRenderFns } from "./PlanDowngrade.vue?vue&type=template&id=7e9e13fd&scoped=true&"
import script from "./PlanDowngrade.vue?vue&type=script&lang=js&"
export * from "./PlanDowngrade.vue?vue&type=script&lang=js&"
import style0 from "./PlanDowngrade.vue?vue&type=style&index=0&id=7e9e13fd&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7e9e13fd",
  null
  
)

export default component.exports