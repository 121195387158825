<template>
  <div>
    <b-notification aria-close-label="Close notification">
      他社や他社サービスとの違いを、優先度の高い順に５件まで掲載できます。<br>
      できるだけ専門用語を使わず、顧客が分かる言葉で記載しておく必要があります。<br>
      <a href="https://docs.google.com/document/d/19bXDMsUsGqgS053byjTOhWuvpuru5SJyNnRZG3jynQ8/edit?usp=sharing" target="_blank">特長（選ばれる理由）の掲載ポイントを見る&nbsp;<span class="icon-external-link"></span></a><br>
      <br>
      ※タイトルの右側にある「︙」をクリックすると、順番の変更が可能です。
    </b-notification>

    <img class="pb-3" src="https://storage.googleapis.com/admin-ilb.appspot.com/ilbnet-jp/feature.png">

    <!--ここから-->
    <div v-if="updateFlg">
      <div class="content" v-for="key of 5" :key="key">
        <div class="move-panel">
          <b-dropdown aria-role="list" class="is-pulled-right" position="is-bottom-left">
          <template #trigger>
            <b-icon icon="dots-vertical"></b-icon>
          </template>
            <b-dropdown-item aria-role="listitem" @click="menuMove('feature', (key -1),'up', true)" :disabled="(key-1) === 0">一番上に移動</b-dropdown-item>
            <b-dropdown-item aria-role="listitem" @click="menuMove('feature',(key-1),'up')" :disabled="(key-1) === 0">一つ上に移動</b-dropdown-item>
            <b-dropdown-item aria-role="listitem" @click="menuMove('feature',(key-1),'down')" :disabled="(key-1) === (pageData['feature'].length - 1)">一つ下に移動</b-dropdown-item>
            <b-dropdown-item aria-role="listitem" @click="menuMove('feature',(key-1),'down', true)" :disabled="(key-1) === (pageData['feature'].length - 1)">一番下に移動</b-dropdown-item>
          </b-dropdown>
        </div>

        <b-collapse
          aria-id="contentIdForA12y2"
          class=""
          animation="slide"
          v-model="isOpen">
          <template #trigger>
            <div
              class="panel-heading mb-3"
              role="button"
              aria-controls="contentIdForA12y2">
              <strong v-if="pageData['feature'][key-1]['title']">{{ pageData['feature'][key-1]['title']}}</strong>
              <strong v-else>特長{{ key }}のタイトルを入力してください</strong>
            </div>
          </template>
          <div class="field pb-3">
            <label class="label has-text-weight-bold" :for="'feature_title_' + key">特長{{ key }}のタイトル </label>
            <p class="pb-1" v-if="(key-1) === 0">
              例１）２０年の実績があるダイエット<br>
              例２）腰痛の解消に特化している<br>
              例３）歯並びのお悩みには全て対応可能
            </p>
            <b-input
              :id="'feature_title_' + key"
              v-model="pageData['feature'][key-1]['title']"
              maxlength="15"
              @blur="trim('feature', key-1, 'title')"
              >
              </b-input>
          </div>

          <div class="field pb-3">
            <label class="label has-text-weight-bold" :for="'feature_image_' + key">特長{{ key }}の画像</label>
            <p class="pb-1">
              特長をイメージできる画像を登録してください。<br>
              ※スマホからでも見やすいように横長のサイズ（横幅4：縦幅3 程度の比率）で文字が入っていない画像がオススメです。
            </p>
            <div class="columns">
              <div v-if="!imageFile[key-1].isDeleted" class="column is-one-quarter">
                <img :src="pageData['feature'][key-1]['image']" width="100px">
              </div>
              <div class="column">
                <photo-resize :image-title="'feature_image'" :doc-field-image-title="'image'"
                :array-num="key-1" :doc-field-title="'feature'"
                :src="pageData['feature'][key-1]['image']" @featureimage="imgUpdate" ref="featureimage"/>
              </div>
            </div>
          </div>

          <div class="field pb-3">
            <label class="label has-text-weight-bold" :for="'feature_detail_' + key">&nbsp;特長{{ key }}の詳細</label>
            <p class="pb-1" v-if="(key-1) === 0">
              例）当店は 2,800件以上の施術実績があります。多くの施術を通して、知識と技術の向上につとめています。お客様の様々なお悩みに対応が可能です。
            </p>
            <b-input
              :id="'feature_detail_' + key"
              v-model="pageData['feature'][key-1]['detail']"
              maxlength="100"
              type="textarea"
              @blur="trim('feature', key-1, 'detail')"
              >
              </b-input>
          </div>
        </b-collapse>
      </div>
    </div>
    <!-- ここまで  -->
  </div>
</template>

<script>
import 'bulma/css/bulma.css'
import PhotoResize from '@/components/PhotoResize.vue'

export default {
  props: ['doc','title'],
  computed: {
    pageData: {
      get() {
        return this.doc;
      },
      set(data) {
        this.$emit('doc', data);
      }
    },
  },
  components: {
    'PhotoResize': PhotoResize
  },
  methods: {
    // photoResize→親（Main.vue）へ渡す画像用配列：imageFileData
    imgUpdate: function (imageFileData) {
      this.imageFile[imageFileData["arrayNum"]] = imageFileData
      // 子コンポーネント画面再描画用
      this.updateFlg = Math.random()
      this.$emit('imgUpdate', imageFileData);
    },
    // 親（Main.vue）→photoResize.reset(画面上の画像表示削除させる)
    reset : function(){
      Object.keys(this.$refs.featureimage).map(async key => {
        this.$refs.featureimage[key].reset()
      })
    }
  },
  data () {
    return {
      isOpen : true,
      updateFlg: true, // 再描画用
      imageFile: Array.from(
        Array(5)
        ).map(
          () => {
            return {
              isDeleted: false
            }
          }
        ),
    }
  }
}
</script>

<style>
input[readonly='readonly']{
background-color:#c0c0c0;
color:#666;
}
.move-panel {
  position:relative;
  top:12px;
  left: -1%;
}
</style>
