<template>
  <form @submit.prevent="validateForm">
    <section class="row payment-form">
        <h5>
          広告の解約
        </h5>
        <p class="has-text-centered service-title">
          広告の着地先URL<br>
          {{ pagedata['business_name'] }} {{ pagedata['branch_name'] }}<br>
          <a :href="'https://jp.ilb.net/' + pagedata['page_id'] + '/'" target="_blank">
              https://jp.ilb.net/{{ pagedata['page_id'] }}/
          </a>
        </p>

        <b>広告の種類</b>
        <p class="is-size-7">広告の種類を選択してください</p>
        <p class="service-type">
          <b-radio
            v-if="gsDisable"
            v-model="serviceName"
            name="希望の広告"
            native-value="gs"
            disabled
            >
              Google広告（検索）<span v-if="gsSuspend" style="color:#000;">：停止中</span>
          </b-radio>
          <b-radio
            v-else
            v-model="serviceName"
            name="希望の広告"
            native-value="gs"
            v-validate="'required'"
            >
              Google広告（検索）<span v-if="gsSuspend" style="color:#000;">：停止中</span>
            </b-radio>
          <b-radio
            v-if="gdDisable"
            v-model="serviceName"
            name="希望の広告"
            native-value="gd"
            disabled
          >
            Google広告（ディスプレイ）<span v-if="gdSuspend" style="color:#000;">：停止中</span>
          </b-radio>
          <b-radio
            v-else
            v-model="serviceName"
            name="希望の広告"
            native-value="gd"
            v-validate="'required'"
          >
              Google広告（ディスプレイ）<span v-if="gdSuspend" style="color:#000;">：停止中</span>
          </b-radio>
        <span class="help is-danger" v-show="errors.has('広告の種類')">{{ errors.first('広告の種類') }}</span>
        </p>

        <div v-if="serviceName">
          <p class="has-text-centered is-size-7">広告を解約すると決済が停止されます。</p>
          <div class="col s12 place-order-button-block">
            <b-button
              v-if="success === false"
              class="btn col s12 #e91e63 pink"
              type="is-success has-text-weight-bold"
              @click="validateForm"
              :disabled="errors.any() || !isFormCompleted"
              >
                解約内容を確認する
            </b-button>
            <b-button v-if="success === 'loading'" loading>Loading</b-button>
            <b-button v-if='success === true' class="btn col s12 #e91e63 pink" @click="modalClose">閉じる</b-button>
            <b-loading :is-full-page="isFullPage" :active.sync="isLoading"></b-loading>
            <p class="mt-2 has-text-centered">
              <a href="#" @click="$emit('modalclose')">管理画面に戻る</a>
            </p>
          </div>
        </div>
    </section>
  </form>
</template>

<script>
import Config from '../../config.js'
import moment from 'moment'

export default {
    props: ['pagedata', 'adsdata', 'chargesdata'],
    computed: {
      budget() {
        return this.adsdata[this.serviceName].budget
      },
      gsDisable() {
        if (this.chargesdata.gs && this.chargesdata.gs.payment_date) {
          return false
        }
        return true
      },
      gsSuspend() {
        if (this.adsdata.gs && this.adsdata.gs.status === 'suspend') {
          return true
        }
        return false
      },
      gdDisable() {
        if (this.chargesdata.gd && this.chargesdata.gd.payment_date) {
          return false
        }
        return true
      },
      gdSuspend() {
        if (this.adsdata.gd && this.adsdata.gd.status === 'suspend') {
          return true
        }
        return false
      },
      isFormCompleted: function() {
        if (
          !this.serviceName
        ) {
          return false
        }
        return true
      },
    },
    data() {
        return {
            serviceName: null,
            success:false,
            isLoading: false,
            isFullPage: true,
        }
    },
    methods: {
      async buttonPressed() {
        // loadingのため設定
        this.success = 'loading'
        this.isLoading = true
        let swal = this.$swal
        const instance = this


        let adCancelBtnClick = false
          await swal
          .mixin({
            onOpen: () => {
                document.getElementById("ad-cancel-button")
                  .addEventListener( "click", function () {
                    adCancelBtnClick = true
                    swal.clickConfirm()
                }),
                document.getElementById("close-modal")
                  .addEventListener( "click", function () {
                    swal.clickConfirm()
                    instance.$emit('modalclose')
                })
            }
          })
          .fire(
            {
            title : '広告を解約しますか？',
            icon: 'warning',
              html : this.$store.state.admin_data.service_name[this.serviceName].name
                + '<br>'
                + '広告費：'
                + this.budget.toLocaleString() + '円'
                + '<br><br>'
                + '未使用の広告費がある場合、広告運用は継続されます。'
                + '<br><br>'
                + '決済ずみの広告費は返金できません。'
                + '<br><br>'
                + '<button id="ad-cancel-button" type=“button” value="広告を解約する" class="button is-fullwidth is-danger is-large">広告を解約する</button>'
                + '</div>'
                + '<br>'
                +'<p class="">'
                + '<a id="close-modal" href="#">管理画面に戻る</a>'
                + '</p>',
            showConfirmButton: false,
            allowOutsideClick: false,
          });

        if(!adCancelBtnClick) {
          await this.$emit('modalclose')
          return
        }

        try {
          let data = {
            template_id : 'AD_CANCEL_SUCCESS',
            to : this.pagedata.email || this.$store.state.user.email,
            url :Config.DESTINATION_URL + '/' + this.$store.state.page_id + '/',
            admin_url: Config.MANAGE_URL,
            page_id: this.$store.state.page_id,
            service_name : this.$store.state.admin_data.service_name[this.serviceName].name,
            budget : this.budget.toLocaleString(),
            business_name: this.pagedata.business_name,
            branch_name: this.pagedata.branch_name || '',
            category: this.pagedata.category || '',
            email_auth : this.$store.state.user.email,
            status: 'sending',
            date: moment().format("YYYY-MM-DD HH:MM:SS"),
            name: this.$store.state.admin_data.service_name[this.serviceName].name,
            short_name: this.$store.state.admin_data.service_name[this.serviceName].short_name,
            system_name: this.$store.state.admin_data.service_name[this.serviceName].system_name,
            country_code: this.$store.state.admin_data.service_name.url.country_code,
            tld: this.$store.state.admin_data.service_name.url.tld,
          }
          await Config.SENDMAIL_TARGET_COLLECTION.add(data);

          // charges_dataの更新（payment_dateをnullに）
          await Config.CHARGES_COLLECTION.doc(
            this.$store.state.page_id
            ).set({
              [this.serviceName] : {
                payment_date : ''
              }},
              { merge: true }
            );

        } catch (e){
          await this.$swal({
              title: '広告の解約が失敗しました。',
              text:'管理者に連絡してください:' + e.message,
              confirmButtonText: '管理画面に戻る',
              icon:'error',
          })
          return
        }

        // 確認用のswal
        let swalParam = await this.getSwalParam()
        await this.$swal(swalParam);
        await this.$emit('modalclose')
        this.success = true
        this.isLoading = false
        return
      },
      getSwalParam() {
        return {
          title : '広告を解約しました',
          html : this.$store.state.admin_data.service_name[this.serviceName].name
            + '<br>'
            + '広告費：'
            + this.budget.toLocaleString() + '円'
            + '<br><br>'
            + '再度、広告を実施したい場合'
            + '<br>'
            + '<b>Google広告を申込む</b>'
            + '<br>'
            + 'から、お申込みください',
          confirmButtonText : '管理画面に戻る',
          icon:'success'
        }
      },
      modalClose() {
          this.$emit('modalclose')
      },
      validateForm() {
        this.$validator.validateAll().then((result) => {
          if (result) {
            this.buttonPressed();
            return;
          }
        });
      }
    },
}
</script>

<style scoped>
.service-title{
  margin-bottom:6px;
}
.service-type {
  margin-bottom:8px;
}
.payment-form {
    max-width: 400px;
    margin: 20px auto;
    border: 2px solid #ececec;
    padding: 12px;
    margin-bottom: 8px;
}
.payment-form h5 {
    margin: 5px;
    padding: 0px;
    text-align:center;
    font-size: 1.2rem;
}
.payment-form h6 {
    margin-bottom: 5px;
    padding: 0px;
    text-align:center;
    font-size: 1.5rem;
}

.place-order-button-block {
    margin: 5px 0;
    text-align:center;
}

button {
  background-color: #23D160;
  color: white;
  border-radius: 4px;
  font-size: 20px;
  /* font-weight: 600; */
  border: 0;
  box-shadow: none;
  padding: 8px 24px;
}
</style>
