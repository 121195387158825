<template>
  <div>
    <div v-if="pageData['business_name']
      && pageData['phone'] && pageData['email'] && pageData['zip_code'] && pageData['region'] && pageData['city'] && pageData['street_address']
      && pageData['category_group'] && pageData['category'] && pageData['catchphrase']"></div>
    <div v-else>
      <b-message type="is-danger is-light mb-3">
        必須項目を全て入力するとページが公開されます。<br>
        ※現在ページは非公開となっていますので、必須項目を入力してください。<br>
      </b-message>
    </div>

    <div class="field">
      <label class="label has-text-weight-bold" for="public">{{ title.public }}</label>
      <b-switch v-model="pageData['public']" @click="pageData['public']=!pageData['public']">
        <strong>{{ pageData['public'] ? `公開` : `非公開`}}</strong>
      </b-switch>
    </div>

    <div class="field">
      <label class="label has-text-weight-bold" for="category_group">{{ title.category_group }}</label>
      <p class="pb-1">
        該当の業種が選択肢にない場合、大業種は「その他」を選択して、小業種を入力してください。<br>
        <a href="https://docs.google.com/spreadsheets/d/1Ee-bgiuk_yY632l5AuIIRVZCam-PhjPvMn6zU9Me0xg/edit#gid=0" target="_blanc">業種の一覧を見る&nbsp;<span class="icon-external-link"></span></a>
      </p>
      <div class="select">
        <select id="category_group" v-model="selected_category_group" placeholder="選択してください">
          <option v-for="(value, key) in category_group" :key="key" :value="value">{{ value }}</option>
        </select>
      </div>
    </div>

    <div class="field pb-4">
      <label class="label has-text-weight-bold" for="category">{{ title.category }}</label>
      <div v-if='pageData["category_group"]=="その他"'>
        <b-input id="category" v-model="pageData['category']" maxlength="15" @blur="trim('category')" />
      </div>
      <div v-else class="select">
        <b-select id="category" v-model="selected_category" placeholder="選択してください">
          <option v-for="(value, key) in categories" :key="key" :value="value">{{ value }}</option>
        </b-select>
      </div>
    </div>
    <div class="field pb-5 notification is-danger is-light" v-if="(gsAdsStatus || gdAdsStatus ) && strCount(pageData['city'] + 'の' + pageData['category']) > 30">
      <label class="label has-text-weight-bold">広告で使用する「市区町村の小業種」</label>
      <p class="has-text-dark">現在の入力内容：<span class="has-text-weight-bold">{{ pageData['city'] + 'の' + pageData['category']}}</span>（<span class="has-text-danger">{{strCount(pageData['city'] + 'の' + pageData['category'])}}文字</span>）</p>
      <p class="has-text-dark">「市区町村」と「小業種」の文字数（合計）が広告設定できる上限文字数を超えています。</p>
      <p class="has-text-danger">半角30文字（全角15文字）以内で入力してください。</p>
      <input id="ads_title" class="input" v-model="adsTitle" @focus="onFocus('ads_title')">
      <small v-if="ads_title_focus" class="help counter ads-title-counter">
        {{ strCount(adsTitle) }} / 30
      </small>
    </div>

    <div class="field pb-3 notification is-info is-light">
      <label class="label has-text-weight-bold" for="google-map">Googleビジネスプロフィール（Place ID）</label>
      <p class="pb-1">
        事業所名（店名や会社名）と市区町村名を入力し、表示された候補の中から御社の情報を選択してください。<br>
        ※選択するとGoogleビジネスプロフィールに登録されているデータ（事業所名、電話番号、郵便番号、住所、営業時間、地図上の位置）が自動で入力されます。<br>
        <span class="has-text-danger">※誤って他社の情報を選択すると、情報が上書きされますのでご注意ください。</span>
      </p>
      <b-input id="place_autocomplete" @focus="initializePlaceAutocomplete" placeholder="事業所名と市区町村名を入力" class="pb-3"></b-input>
    </div>

    <div class="field pb-3">
      <div v-if="pageData['place_id'] == ''">
        <p>候補に御社の情報が表示されなかった場合は、下記よりGoogleビジネスプロフィールの登録方法をご確認ください。</p>
        <a href="https://docs.google.com/document/d/1uNy6taO2r_tIEkrEtnF9CHJGB4WiNlU-Y6sEu1mijNA/edit?usp=sharing" target="_blanc" class="">Googleビジネスプロフィール 新規登録する方法&nbsp;<span class="icon-external-link"></span></a>
        <p class="has-text-danger">※Googleビジネスプロフィールに新規登録して間もない場合、検索の候補に表示がされないことがあります。その場合、後日あらためて入力・選択してください。</p>
      </div>
      <div v-else>
        <p class="">選択したGoogleビジネスプロフィール（Place ID）</p>
        <b-input id="place_id" v-model="pageData['place_id']" readonly></b-input>
        <p class="pt-3">選択したGoogleビジネスプロフィールを確認するためには、下記URLをクリックしてください。<br></p>
        <a :href="'https://www.google.com/maps/place/?q=place_id:' + pageData['place_id']" target="_blank">
          https://www.google.com/maps/place/?q=place_id:{{ pageData['place_id'] }}
        </a>
        <br>
        <button class="button is-danger is-inverted is-pulled-right" @click="removeText">
          Googleビジネスプロフィールの値を削除する
        </button>
        <br>
      </div>
    </div>

    <b-message type="is-danger is-light">
      Googleビジネスプロフィール（Place ID）を選択すると<br>
      １．Googleビジネスプロフィールに投稿されたクチコミがページに表示できます<br>
      ２．Googleビジネスプロフィールへのクチコミ募集用チラシが作成できます<br>
      ３．地図の位置を正確に表示できます<br>
      ※Googleビジネスプロフィールで設定した位置が表示されます
    </b-message>

    <div class="field py-3">
      <label class="label has-text-weight-bold" for="business_name">{{ title.business_name }}</label>
      <p class="pb-1">
        御社の店名や会社名を入力してください。<br>
        例）ILB整骨院
      </p>
      <input id="business_name" class="input" v-model="businessName" @focus="onFocus('business_name')">
      <small v-if="business_name_focus" class="help counter business-name-counter">
        {{ len }} / 25
      </small>
      <div v-if="isFormCompleted">
        <p><span class="has-text-danger">半角25文字（全角12文字）以内で入力してください</span></p>
      </div>
    </div>

    <div class="field pb-3">
      <label class="label has-text-weight-bold" for="business_name_ja"><span class="tag is-light">任意</span>&nbsp;{{ title.business_name_ja }}</label>
      <p class="pb-1">
        事業所名が外国語表記や読みにくい漢字の場合、読みにくい部分のフリガナを入力してください。<br>
        例）アイエルビー
      </p>
      <b-input id="business_name_ja" v-model="pageData['business_name_ja']" maxlength="20" @blur="trim('business_name_ja')"></b-input>
    </div>

    <div class="field pb-3">
      <label class="label has-text-weight-bold" for="branch_name">
        <span class="tag is-light">任意</span>
        &nbsp;{{ title.branch_name }}
      </label>
      <p class="pb-1">
        事業所が支店や支社の場合のみ入力してください。<br>
        例）新宿店
      </p>
      <b-input id="branch_name" v-model="pageData['branch_name']" maxlength="20" class="pb-1" @blur="trim('branch_name')"></b-input>
    </div>

    <div class="field pb-3">
      <label class="label has-text-weight-bold" for="phone">{{ title.phone }}</label>
      <p class="pb-1">
        顧客からの問合せを受ける際の電話番号です。ページに掲載されます。<span class="has-text-danger">半角（ハイフンあり）</span>で入力してください。<br>
        例）092-707-3658
      </p>

      <b-field
        :type="errors.has('phone') ? 'is-danger': ''"
        :message="errors.has('phone') ? errors.first('phone') : ''">
        <b-input
              name="phone"
              v-model="pageData['phone']"
              v-validate="{ required:true, regex: /^0[1-9][0-9]{0,4}-[0-9]{1,5}-[0-9]{1,5}$/ }"
              data-vv-as="電話番号"
             >
        </b-input>
      </b-field>
    </div>

    <div class="field pb-3">
      <label class="label has-text-weight-bold" for="phone_contact"><span class="tag is-light">任意</span>&nbsp;{{ title.phone_contact }}</label>
      <p class="pb-1">
        当社（ILB）から連絡を受ける際の電話番号です。電話番号（ページ掲載用）と異なる場合のみ、<span class="has-text-danger">半角（ハイフンあり）</span>で入力してください。<br>
        例）090-1234-5678
      </p>

      <b-field
        :type="errors.has('phone_contact') ? 'is-danger': ''"
        :message="errors.has('phone_contact') ? errors.first('phone_contact') : ''">
        <b-input
              name="phone_contact"
              v-model="pageData['phone_contact']"
              v-validate="{ regex: /^0[1-9][0-9]{0,4}-[0-9]{1,5}-[0-9]{1,5}$/ }"
              data-vv-as="電話番号"
             >
        </b-input>
      </b-field>
    </div>

    <div class="field pb-3">
      <label class="label has-text-weight-bold" for="email">{{ title.email }}</label>
      <p class="pb-1">
        問合せ内容が届くメールアドレスです。<span class="has-text-danger">半角</span>で入力してください。<br>
        例）support@vegefru.org
      </p>
      <b-field
        :type="errors.has('email') ? 'is-danger': ''"
        :message="errors.has('email') ? customMessage: ''">

        <b-input
              name="email"
              v-model="pageData['email']"
              v-validate.immediate="{ required:true, regex:regex_email, email:true}"
              data-vv-as="メールアドレス"
             >
        </b-input>
      </b-field>
    </div>

    <div class="field">
      <label class="label has-text-weight-bold" for="zip_code">{{ title.zip_code }}</label>
      <p class="pb-1">
        郵便番号を<span class="has-text-danger">半角（ハイフンあり）</span>で入力してください。<br>
        例）810-0075
      </p>
      <b-field
        :type="errors.has('zip_code') ? 'is-danger': ''"
        :message="errors.has('zip_code') ? errors.first('zip_code') : ''">
        <b-input
          name="zip_code"
          id="zip_code"
          v-model="pageData['zip_code']"
          @input="fetchAddress"
          v-validate.immediate="{ regex: /^[0-9]{3}-[0-9]{4}$/ }"
          data-vv-as="郵便番号"
        >
        </b-input>
      </b-field>
    </div>

    <div class="field">
      <label class="label has-text-weight-bold" for="region">{{ title.region }}</label>
      <b-input id="region" v-model="pageData['region']" readonly></b-input>
    </div>


    <div class="field">
      <label class="label has-text-weight-bold" for="city">{{ title.city }}</label>
      <b-input id="city" v-model="pageData['city']" readonly></b-input>
    </div>


    <div class="field">
      <label class="label has-text-weight-bold" for="street_address">
        <span class="tag is-light">
          任意
        </span>
        &nbsp;{{ title.street_address }}
      </label>
      <b-input id="street_address" v-model="pageData['street_address']" @blur="trim('street_address')"></b-input>
    </div>


    <div class="field pb-3">
      <label class="label has-text-weight-bold" for="building_name"><span class="tag is-light">任意</span>&nbsp;{{ title.building_name }}</label>
      <b-input id="building_name" v-model="pageData['building_name']" maxlength="30" @blur="trim('building_name')"></b-input>
    </div>

    <div v-if="pageData['place_id'] == ''" class="field pb-3">
      <b-message type="is-danger is-light">
        入力いただいた住所を元にページ上に地図を表示します。地図上の位置が、実際の位置と異なる場合、Googleビジネスプロフィール（Place ID）を選択いただくことで、正しい位置を表示可能です。
      </b-message>
    </div>

    <div class="field pb-3">
      <label class="label has-text-weight-bold" for="catchphrase">{{ title.catchphrase }}</label>
      <img class="pb-1" src="https://storage.googleapis.com/admin-ilb.appspot.com/ilbnet-jp/firstview.png">
      <p class="pb-1">
        御社の特長を端的に表すキャッチフレーズを入力してください。<br>
        例１）骨盤矯正に特化した整体院<br>
        例２）無理なく痩せるダイエット専門店<br>
        例３）オーガニック野菜のサラダが人気
      </p>
      <b-input id="catchphrase" v-model="pageData['catchphrase']" maxlength="20" @blur="trim('catchphrase')"></b-input>
    </div>

    <div v-if="updateFlg" class="field pb-3">
      <label class="label has-text-weight-bold">{{ title.business_image }}</label>
      <p class="pb-1">
        御社のイメージ画像を登録してください。<br>
        ※スマホからでも見やすいように横長のサイズ（横幅4：縦幅3 程度の比率）で文字が入っていない画像がオススメです。
      </p>
      <div class="columns">
        <div v-if="!imageFile.isDeleted" class="column is-one-quarter">
          <img :src="pageData['business_image']" width="100px">
        </div>
        <div class="column">
          <photo-resize :image-title="'business_image'" :src="pageData['business_image']" @businessimage="imgUpdate" ref="businessimage"/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import 'bulma/css/bulma.css'
import {category_group, category} from '../../const.js'
// import moment from 'moment'
import axiosJsonpAdapter from 'axios-jsonp'
import axios from 'axios'
import Config from '../../config.js'
import GoogleMapsApiLoader from 'google-maps-api-loader'
import PhotoResize from '@/components/PhotoResize.vue'

export default {
  props: ['doc', 'title', 'charges_doc'],
  components: {
    'PhotoResize': PhotoResize,
  },
  computed: {
    pageData: {
      get() {
        return this.doc;
      },
      set(data) {
        this.$emit('doc', data);
      }
    },
    selected_category: {
      get() {
        return this.pageData['category']
      },
      set (value) {
        if (value !== undefined && value !== null) {
          this.pageData["category"] = value
        }
      },
    },
    selected_category_group: {
      get () {
        if (!this.pageData["category_group"]) {
          return
        }
        // category_group取得時にcategoryも入れる
        this.selectedCategoryGroup(this.pageData["category_group"])
        return this.pageData["category_group"]
      },
      set (value) {
        // set時にpageDataに入れてcategoryプルダウンを更新
        this.pageData["category_group"] = value
        this.pageData['category'] = ''

        this.selectedCategoryGroup(value)
      }
    },
    businessName: {
      get () {
        return this.pageData["business_name"]
      },
      set (value) {
        this.pageData["business_name"] = this.charaCountAndLimitStr(value, 25)['str']
      }
    },
    len () {
      return this.charaCountAndLimitStr(this.pageData["business_name"], 25)['len']
    },
    adsTitle: {
      get () {
        return this.pageData["ads_title"]
      },
      set (value) {
        this.pageData["ads_title"] = this.charaCountAndLimitStr(value, 30)['str']
      }
    },
    // Google検索広告の契約中・未契約
    gsAdsStatus () {
      if (this.charges_doc && this.charges_doc.gs && this.charges_doc.gs.payment_date) {
          return true;
      }
      return false
    },
    // Googleディスプレイ広告の契約中・未契約
    gdAdsStatus () {
      if (this.charges_doc && this.charges_doc.gd && this.charges_doc.gd.payment_date) {
          return true;
      }
      return false
    },
  },
  methods: {
    onFocus : function(field) {
      this[field + '_focus'] = true
    },
    // photoResize→親（Main.vue）へ渡す画像用配列：imageFileData
    imgUpdate: function (imageFileData) {
      this.imageFile = imageFileData
      // 子コンポーネント画面再描画用
      this.updateFlg = Math.random()
      this.$emit('imgUpdate', imageFileData);
    },
    // 親（Main.vue）→photoResize.reset(画面上の画像表示削除させる)
    reset : function(){
      this.$refs.businessimage.reset()
    },
    /**
     * GooglePlaces
     */
    initializePlaceAutocomplete() {
      // Google Placesのオートコンプリートを設定する
      const autocomplete = new this.googleMap.maps.places.Autocomplete(document.getElementById("place_autocomplete"))
      autocomplete.setFields(["place_id", "geometry", "name", "formatted_phone_number", "address_components", "opening_hours", 'website'])

      autocomplete.addListener("place_changed", () => {
        // 選択されたPlaceデータを取得する
        var place = autocomplete.getPlace()

        if (!place.geometry) {
          return
        }

        // 取得したPlaceデータをpageDataに設定する
        const getAddressComponentByType = type => {
          const data = place.address_components.find(address_component => address_component.types.includes(type))
          return data ? data.long_name : ''
        }
        this.businessName = place.name ? this.multByteStringSlice(place.name, 25) : ''
        this.pageData["phone"] = place.formatted_phone_number ? place.formatted_phone_number : ''
        this.pageData["zip_code"] = getAddressComponentByType('postal_code')
        this.pageData["region"] = getAddressComponentByType('administrative_area_level_1')
        this.pageData["city"] = getAddressComponentByType('administrative_area_level_2') + getAddressComponentByType('locality') + getAddressComponentByType('sublocality_level_1')
        this.pageData["street_address"] = getAddressComponentByType('sublocality_level_2') + getAddressComponentByType('sublocality_level_3') + getAddressComponentByType('sublocality_level_4')
        this.pageData["building_name"] = ''  // 建物名は空白で更新
        this.pageData["official_url"] = place.website ? place.website : ''

        // 営業時間を設定
        for (let i = 0; i <= 7; i++) {
          // 最初に初期値を設定しておく
          this.pageData["opening"]["day" + i] = {
            open_day: false,
            open_hours: [ { opens: '', closes: '' } ],
          }

          // 該当する曜日があれば設定する
          if (place.opening_hours) {
            const periods = place.opening_hours.periods.filter(period => period.close.day === i)
            if (periods.length) {
              this.pageData["opening"]["day" + i]["open_day"] = true
              for (let j = 0; j < 2; j++) {
                if (periods[j]) {
                  this.pageData["opening"]["day" + i]["open_hours"][j] = {
                    opens: periods[j].open.time.slice(0, 2) + ':' + periods[j].open.time.slice(2),
                    closes: periods[j].close.time.slice(0, 2) + ':' + periods[j].close.time.slice(2),
                  }
                }
              }
            }
          }
        }

        this.pageData["place_id"] = place.place_id ? place.place_id : ''

        // レビューの取得、更新をする
        this.pageData["review_date"] = ''
        // this.updateReviewData(this.pageData["place_id"], false)
      })
    },
    // /**
    //  * レビュー更新
    //  */
    // updateReviewData(placeId, swalOn = true) {
    //   // レビューデータを取得する
    //   if (this.isReviewUpdateTarget()) {
    //     if (this.pageData['review_date'] < moment().format('YYYY-MM-DD')) {
    //       const service = new this.googleMap.maps.places.PlacesService(document.createElement('div'))
    //       service.getDetails({
    //         placeId: placeId,
    //         fields: ['rating', 'reviews', 'user_ratings_total']
    //       }, (place, status) => {
    //         if (status == this.googleMap.maps.places.PlacesServiceStatus.OK) {
    //           this.setReviewDataToDoc(place)

    //           if (swalOn)  {
    //             this.$swal.fire({
    //               text: 'クチコミを更新しました',
    //               icon: 'success',
    //             })
    //           }
    //         } else {
    //           this.$swal.fire({
    //             text: 'クチコミが取得出来ませんでした',
    //             icon: 'warning',
    //           })
    //         }
    //       })
    //     } else {
    //       this.$swal.fire({
    //         text: 'クチコミの更新は１日１回までとなります',
    //         icon: 'warning',
    //       })
    //     }
    //   } else {
    //     this.setReviewDataToDoc({})
    //   }
    // },
    // /**
    //  * レビュー保存
    //  */
    // setReviewDataToDoc(place) {
    //   this.pageData["review_display"] = true
    //   this.pageData["review_rating"] = place.rating ? place.rating : 0
    //   this.pageData["review_number"] = place.user_ratings_total ? place.user_ratings_total : 0

    //   for (let i = 0; i < 5; i++) {
    //     if (place.reviews && place.reviews[i]) {
    //       this.pageData["review" + (i + 1) + "_name"] = place.reviews[i].author_name ? place.reviews[i].author_name : ''
    //       this.pageData["review" + (i + 1) + "_rating"] = place.reviews[i].rating ? place.reviews[i].rating : 0
    //       this.pageData["review" + (i + 1) + "_text"] = place.reviews[i].text ? place.reviews[i].text.replace(/\r?\n/g, '') : ''
    //       this.pageData["review" + (i + 1) + "_display"] = place.reviews[i].rating && place.reviews[i].rating >= 3 ? true : false
    //     } else {
    //       this.pageData["review" + (i + 1) + "_name"] = ''
    //       this.pageData["review" + (i + 1) + "_rating"] = 0
    //       this.pageData["review" + (i + 1) + "_text"] = ''
    //       this.pageData["review" + (i + 1) + "_display"] = false
    //     }
    //   }

    //   this.pageData["review_date"] = (Object.keys(place).length) ? moment().format('YYYY-MM-DD') : ''
    // },
    // isReviewUpdateTarget() {
    //   return this.pageData['place_id']
    // },
    multByteStringSlice(str, size) {
      var b = 0;
      for (var i = 0;  i < str.length; i++) {
        b += str[i].match(/[ -~]/)
        ? 1
        : !str[i].match(/^[ｦ-ﾟ]*$/)
        ? 2
        : 1;
        if (b > size) {
          return str.substr(0, i);
        }
      }
      return str;
    },
    //place_idとreviewの値削除
    removeText: function() {
      this.pageData['place_id'] = '';
      this.pageData['review_rating'] = 0;
      this.pageData['review_number'] = 0;
      this.pageData['review_date'] = '';
      this.pageData['review1_name'] = '';
      this.pageData['review1_rating'] = 0;
      this.pageData['review1_text'] = '';
      this.pageData['review1_display'] = false;
      this.pageData['review2_name'] = '';
      this.pageData['review2_rating'] = 0;
      this.pageData['review2_text'] = '';
      this.pageData['review2_display'] = false;
      this.pageData['review3_name'] = '';
      this.pageData['review3_rating'] = 0;
      this.pageData['review3_text'] = '';
      this.pageData['review3_display'] = false;
      this.pageData['review4_name'] = '';
      this.pageData['review4_rating'] = 0;
      this.pageData['review4_text'] = '';
      this.pageData['review4_display'] = false;
      this.pageData['review5_name'] = '';
      this.pageData['review5_rating'] = 0;
      this.pageData['review5_text'] = '';
      this.pageData['review5_display'] = false;
    },
    /**
     * カテゴリーグループ選択時にカテゴリープルダウンを変更する
     */
    selectedCategoryGroup: function(value) {
      this.categories = this.category[value]
    },
    /**
     * 郵便番号からの住所検索
     */
    async fetchAddress() {
      // 郵便番号のバリデーションチェック
      const reg = /^\d{3}-\d{4}$/g
      let zipcode = this.pageData['zip_code']

      if (!reg.test(zipcode)) {
        this.pageData['region'] = ''
        this.pageData['city'] = ''
        return
      }

      zipcode = zipcode.replace( /-/g , '' )

      // 住所apiを叩く
      await axios.get(Config.ZIPCODE_API_URL + '/api/search', {
        adapter: axiosJsonpAdapter,
        params: {
          zipcode: zipcode,
        }
      }).then((res) => {
        // リクエストが失敗した時は200以外のステータスが返ってくる
        if (res.status !== 200) return

        if (res.data.code === undefined) {
          // 返却されたデータを挿入する
          this.$nextTick(function () {
            this.pageData['region'] = res.data.results[0].address1
            this.pageData['city'] = res.data.results[0].address2
            this.pageData['street_address'] = res.data.results[0].address3
        })} else {
          // 結果がない場合はNULL値で上書き
            this.pageData['region'] = ''
            this.pageData['city'] = ''
            this.pageData['street_address'] = ''
            return
          }
        }
      )
    },
    // business_nameの文字列制限用
    charaCountAndLimitStr (str, set_len) {
      let len = 0
      let s = str.length
      for (let i = 0; i < str.length; i++) {
        (str[i].match(/[ -~]/))
        ? len += 1
        : !str[i].match(/^[ｦ-ﾟ]*$/)
        ? len += 2
        : len += 1;
        if (len > set_len) {
          this.isFormCompletedTrue()
          s = i
          break
        }
      }
      return {len: len, str: str.slice(0, s)}
    },
    isFormCompletedTrue() {
      this.isFormCompleted = true
      setTimeout(() => {
        this.isFormCompleted = false}
        ,3000
      )
    },
    // ads_titleのカウント用
    strCount (str) {
      let len = 0;
      for (let i = 0; i < str.length; i++) {
        (str[i].match(/[ -~]/)) ? len += 1 : len += 2;
        }
      return len;
    }
  },
  data () {
    return {
      isFormCompleted: false,
      business_name_focus: false,
      ads_title_focus: false,
      customMessage: 'メールアドレスのフォーマットが正しくありません',
      regex_email: /^[\x20-\x7e]*$/,
      category_group: category_group,
      category:category,
      categories:{},
      googleMap: null,
      imageFile: {},
      updateFlg:true
    }
  },
  async mounted() {
    this.googleMap = await GoogleMapsApiLoader({
      libraries: ['places'],
      apiKey: Config.GOOGLE_MAP_APIKEY
    })
  },
  watch : {
    // 広告タイトルのリセット
    'pageData.category': function(val) {
      if (this.strCount(this.pageData['city'] + 'の' + val) <= 30) {
        this.pageData['ads_title'] = ''
      }
    },
    // 広告タイトルのリセット
    'pageData.city': function(val) {
      if (this.strCount(val + 'の' + this.pageData['category']) <= 30) {
        this.pageData['ads_title'] = ''
      }
    },
  }
}
</script>

<style>
input[readonly='readonly']{
background-color:#c0c0c0;
color:#666;
}
.move-panel {
  position:relative;
  top:12px;
  left: -1%;
}
.business-name-counter {
  float: right;
  margin-left: .5em;
}
.ads-title-counter {
  float: right;
  margin-left: .5em;
}
.is-clearfix::after {
  display: contents !important;
}

</style>
