<template>
  <div class="mobile-margin">
    <b-navbar fixed-top v-if="window_size <= 769">
      <template #burger>
        <b-navbar-item tag="div">
          <Slide>
            <b-menu v-if="doc['page_id'] || pages.length">
              <b-menu-list>
                <b-menu-item icon="file-chart" label="レポートの確認" :active="activeMenu('/report')" @click="$router.push('/report').catch(() => {})"></b-menu-item>
                <b-menu-item icon="email-arrow-left-outline" label="問い合わせ一覧" :active="activeMenu('/contact_list')" @click="$router.push('/contact_list').catch(() => {})"></b-menu-item>
              </b-menu-list>
            </b-menu>

            <b-menu v-if="doc['page_id'] || pages.length">
              <b-menu-list>
                <b-menu-item icon="square-edit-outline" label="掲載情報の入力・修正" :active="activeMenu('/')" @click="$router.push('/').catch(() => {})"></b-menu-item>
                <!-- <b-menu-item icon="file-chart" label="レポート（ページ）の確認" :active="activeMenu('/page_report')" @click='$router.push( "/page_report" ).catch(() => {})'></b-menu-item> -->
                <b-menu-item icon="mdi mdi-chart-line" label="問合せ数を増やす方法" :active="activeMenu('/page_conversion')" @click="$router.push('/page_conversion').catch(() => {})"></b-menu-item>
                <b-menu-item icon="file-outline" label="チラシの作成" :active="activeMenu('/flyer')" @click="$router.push('/flyer').catch(() => {})"></b-menu-item>
                <b-menu-item icon="qrcode" label="QRコードの作成" target="_blank" @click="openModal('qrlink')"></b-menu-item>
                <!-- <b-menu-item v-if="doc['plan'] === 'standard' && payment_date" icon="cancel" label="決済を停止する" target="_blank" @click="openModal('plandowngrade')"></b-menu-item> -->
                <b-menu-item
                  v-if="doc['plan'] === 'standard' && payment_date"
                  icon="cancel"
                  label="決済を停止する"
                  :active="activeMenu('/payment')"
                  @click="$router.push('/payment').catch(() => {})"
                ></b-menu-item>
                <b-menu-item v-if="doc['plan'] === 'standard' && !payment_date" icon="cancel" label="決済を再開する" target="_blank" @click="$router.push('/payment').catch(() => {})"></b-menu-item>
                <b-menu-item icon="close" label="ページの削除" @click="remove()"></b-menu-item>
              </b-menu-list>
            </b-menu>

            <b-menu v-if="!disapproved && (doc['page_id'] || pages.length)">
              <b-menu-list>
                <b-menu-item icon="google-ads" v-if="adCheckoutMenu" label="Google広告を申込む" target="_blank" @click="openModal('adcheckout')"></b-menu-item>
                <!-- <b-menu-item icon="file-chart" v-if="adReportMenu" label="レポート（広告）の確認" :active="activeMenu('/ads_report')" @click='$router.push( "/ads_report" ).catch(() => {})'></b-menu-item> -->
                <b-menu-item icon="mdi mdi-chart-line" label="問合せ数を増やす方法" :active="activeMenu('/ads_conversion')" @click="$router.push('/ads_conversion').catch(() => {})"></b-menu-item>
                <b-menu-item
                  icon="square-edit-outline"
                  v-if="adDataChangeMenu"
                  label="広告内容の確認"
                  :active="activeMenu('/ads_data')"
                  @click="$router.push('/ads_data').catch(() => {})"
                ></b-menu-item>
                <b-menu-item icon="map-marker-radius" v-if="adDeliveryRangeMenu" label="配信地域の変更" target="_blank" @click="openModal('addeliveryrangechange')"></b-menu-item>
                <b-menu-item icon="credit-card-settings-outline" v-if="adBudgetChangeMenu" label="広告費・決済日の変更" target="_blank" @click="openModal('adbudgetchange')"></b-menu-item>
                <b-menu-item icon="pause-circle-outline" v-if="adStopOrRestartMenu" label="広告の停止・再開" target="_blank" @click="openModal('adstoporrestart')"></b-menu-item>
                <b-menu-item icon="cancel" v-if="adCancelMenu" label="広告の解約" target="_blank" @click="openModal('adcancel')"></b-menu-item>
              </b-menu-list>
            </b-menu>

            <b-menu>
              <b-menu-list>
                <!-- <b-menu-item icon="account-multiple" label="紹介プログラム" :active="activeMenu('/referral')" @click="$router.push('/referral').catch(() => {})"></b-menu-item> -->
                <!-- <b-menu-item icon="cash" label="支払履歴"></b-menu-item> -->
                <b-menu-item icon="help-circle-outline" label="よくある質問" :active="activeMenu('/faq')" @click="$router.push('/faq').catch(() => {})"></b-menu-item>
                <b-menu-item icon="chat-processing-outline" label="LINEで問合せる" target="_blank" @click="lineLink()"></b-menu-item>
                <b-menu-item icon="email" label="メールアドレスの変更" @click="$router.push('/mailchange').catch(() => {})"></b-menu-item>
                <b-menu-item icon="lock-reset" label="パスワードの変更" @click="$router.push('/passwordchange').catch(() => {})"></b-menu-item>
                <b-menu-item icon="exit-to-app" label="ログアウト" @click="$router.push('/login').catch(() => {})"></b-menu-item>
                <b-menu-item v-if="pages.length === 0" icon="close" label="アカウントの削除" @click="withdrawal()"></b-menu-item>
              </b-menu-list>
            </b-menu>
          </Slide>
        </b-navbar-item>
        <b-navbar-item tag="div"> {{ service_name }} </b-navbar-item>
      </template>
    </b-navbar>
    <p v-if="referral_flg" class="has-text-danger pb-3">
      {{ service_name }}<br />
      登録完了しました
    </p>

    <p class="has-text-weight-bold pb-1" v-if="window_size > 770">
      {{ service_name }}
    </p>

    <div v-if="pages.length" class="block">
      <div class="field">
        <label class="label">ページを選択してください</label>
        <b-select v-model="selectedId" @focus="onFocus" @change.native="getSelectedIds()">
          <option v-for="page in pages" :key="page.id" :value="page.id">{{ page.business_name }} {{ page.branch_name }}（{{ page.category }}）</option>
        </b-select>
      </div>

      <div v-if="doc['page_id'] && pages.length" class="mb-3">
        <div class="pb-3">
          ページURL（
          <span v-if="pageUrlCheck && doc['plan'] === 'standard'"> 公開中 </span>
          <span v-else>
            <span class="has-text-danger">非公開</span>
          </span>
          ）<br />
          <a :href="'https://jp.ilb.net/' + (doc['original_id'] || doc['page_id'])" target="_blank"> https://jp.ilb.net/{{ doc['original_id'] || doc['page_id'] }} </a>

          <div v-if="!pageUrlCheck">
            <p class="has-text-danger">※現在ページは非公開となっていますので、必須項目を入力してください。</p>
          </div>
        </div>
        <div v-if="doc['plan'] === 'standard' && payment_date" class="pb-3">
          <p>決済日：毎月{{ payment_date }}日</p>
          <p>
            <a @click="$router.push('/card').catch(() => {})">クレジットカードを変更する</a>
          </p>
        </div>
        <!-- <div class="pb-3">
          <p v-if="doc['plan'] === 'standard' && payment_date == ''">
            {{ expiration_date }}を過ぎると、ページが非公開となります。サービスをご利用いただくためには、
            <span class="has-text-danger">月額利用料</span>
            の決済を再開してください。
          </p>
          <p v-else-if="doc['plan'] === 'free'">現在、ページが非公開となっています。サービスをご利用いただくためには、月額利用料の決済を完了してください。</p>
        </div>
        <div v-if="doc['plan'] === 'standard' && payment_date">
          <p>
            <a @click="openModal('cardchange')">カード情報を変更する</a>
          </p>
        </div> -->
      </div>

      <div v-if="!payment_date && $route.path !== '/payment'" class="pb-3">
        <div v-if="doc['plan'] === 'standard'">
          <p>決済を再開してください</p>
          <p>
            <span class="has-text-danger">
              {{ expired(last_payment_date) }}
            </span>
            <span> を過ぎると、ページが非公開となります。サービスをご利用いただくためには、月額利用料の決済を再開してください。 </span>
          </p>
          <p>
            <b-button class="button is-info" @click="$router.push('/payment').catch(() => {})" expanded> 決済を再開する </b-button>
            <!-- <b-button class="button is-info" @click="openModal('restorestandard')" expanded> 決済を再開する </b-button> -->
          </p>
        </div>
        <div v-else>
          <p class="has-text-weight-bold pt-5">決済を完了してください</p>
          <p>
            <span> 現在、ページが非公開となっています。サービスをご利用いただくためには、月額利用料の決済を完了してください。 </span>
          </p>
          <p>
            <b-button class="button is-info" @click="$router.push('/payment').catch(() => {})" expanded> 決済画面に移動する </b-button>
          </p>
        </div>
      </div>

      <div class="mb-3">
        <b-collapse class="card" animation="slide" :open="doc['original_id'] ? false : true" :aria-id="'contentIdForOriginalIdForm'">
          <template #trigger="props">
            <div class="card-header" role="button" :aria-controls="'contentIdForOriginalIdForm'" :aria-expanded="props.open">
              <p class="card-header-title">転送用URLを{{ doc['original_id'] ? '変更' : '設定' }}する</p>
              <a class="card-header-icon">
                <b-icon :icon="props.open ? 'menu-up' : 'menu-down'"> </b-icon>
              </a>
            </div>
          </template>
          <div class="card-content-id-form">
            <div class="content">
              <p v-if="doc['plan'] === 'standard'" class="mb-0">
                ページURLのhttps://jp.ilb.net/
                <span class="has-text-danger">●●●●●</span>の部分に、ご希望の文字列を設定できます。
              </p>
              <p v-else class="mb-0">ページURLの https://jp.ilb.net/<span class="has-text-danger">●●●●●</span>の部分に、ご希望の文字列を設定できます。</p>
              <b-field :type="errors.has('original_id') ? 'is-danger' : ''" :message="errors.has('original_id') ? customMessageOriginalId : ''" label="https://jp.ilb.net/" horizontal>
                <b-input
                  name="original_id"
                  id="original_id"
                  v-model="original_id"
                  @input="duplicationCheck()"
                  v-validate.immediate="{ regex: regex_original_id }"
                  placeholder=""
                  data-vv-as="オリジナルID"
                  :disabled="doc['plan'] !== 'standard'"
                ></b-input>
              </b-field>
              <div class="pb-2">
                <span v-if="doc['plan'] === 'standard'" class="has-text-danger">※半角英数字とハイフン（-）が使用可能です。決済を停止に変更した場合、転送用URLは削除となります。</span>
                <span v-else class="has-text-danger">*ページが決済停止されているため転送用URLは設定できません</span>
              </div>
              <div v-if="doc['plan'] === 'standard'">
                <div v-if="duplicateID">
                  <button class="button is-info is-fullwidth" disabled>転送用URLを{{ doc['original_id'] ? '変更' : '設定' }}する</button>
                  <p class="has-text-danger mt-1">すでに使用されているURLのため設定できません</p>
                </div>
                <div v-else>
                  <button class="button is-info is-fullwidth" @click="saveOriginalID()">転送用URLを{{ doc['original_id'] ? '変更' : '設定' }}する</button>
                </div>
              </div>
            </div>
          </div>
        </b-collapse>
      </div>
    </div>

    <!-- コンポーネント Modal -->
    <Modal
      v-if="modal"
      @close="closeModal"
      @changeModalCardchange="changeModal('cardchange')"
      @checkout="changeModal('checkout')"
      @changeModalAdBudgetChange="changeModal('adbudgetchange')"
      @changeModalAgentRequest="changeModal('agentrequest')"
      :pagedata="doc"
      :adsdata="ads_doc"
      :chargesdata="charges_doc"
      :salesdata="sales_doc"
      :admindata="admin_doc"
      :type="type"
    />

    <b-menu class="box">
      <b-menu-list label="ご案内">
        <!-- <b-menu-item v-if="agentRequestMenu && doc['page_id'] && pages.length" icon="square-edit-outline" label="ページの代理入力を依頼する" @click="openModal('agentrequest')" /> -->
        <b-menu-item v-if="agentRequestMenu && doc['page_id'] && pages.length" icon="square-edit-outline" label="ページの代理入力を依頼する" @click="$router.push('/agent-request').catch(() => {})" />
        <b-menu-item v-if="standardPlanNotificationMenu && doc['page_id'] && pages.length" icon="star-circle" label="決済を開始する" @click="openModal('checkout')" />
        <b-menu-item v-if="doc['plan'] === 'standard' && !payment_date" icon="cancel" label="決済を再開する" target="_blank" @click="$router.push('/payment').catch(() => {})"></b-menu-item>
        <b-menu-item v-if="googleAdsNotificationMenu && doc['page_id'] && pages.length" icon="google-ads" label="Google広告を申込む" @click="openModal('adcheckout')" />
        <b-menu-item icon="plus-circle-outline" label="ページを新規作成する" @click="newPage()" />
      </b-menu-list>
    </b-menu>

    <div v-if="window_size > 770">
      <b-menu v-if="doc['page_id'] || pages.length" class="box">
        <b-menu-list label="分析">
          <b-menu-item icon="file-chart" label="レポートの確認" :active="activeMenu('/report')" @click="$router.push('/report').catch(() => {})"></b-menu-item>
          <b-menu-item icon="email-arrow-left-outline" label="問い合わせ一覧" :active="activeMenu('/contact_list')" @click="$router.push('/contact_list').catch(() => {})"></b-menu-item>
        </b-menu-list>
      </b-menu>

      <b-menu v-if="doc['page_id'] || pages.length" class="box">
        <b-menu-list label="ページ">
          <b-menu-item icon="square-edit-outline" :active="activeMenu('/')" label="掲載情報の入力・修正" @click="$router.push('/').catch(() => {})"></b-menu-item>
          <!-- <b-menu-item icon="file-chart" label="レポート（ページ）の確認" :active="activeMenu('/page_report')" @click='$router.push( "/page_report" ).catch(() => {})'></b-menu-item> -->
          <b-menu-item icon="mdi mdi-chart-line" label="問合せ数を増やす方法" :active="activeMenu('/page_conversion')" @click="$router.push('/page_conversion').catch(() => {})"></b-menu-item>
          <b-menu-item icon="file-outline" :active="activeMenu('/flyer')" label="チラシの作成" @click="$router.push('/flyer').catch(() => {})"></b-menu-item>
          <b-menu-item icon="qrcode" label="QRコードの作成" target="_blank" @click="openModal('qrlink')"></b-menu-item>
          <!-- <b-menu-item v-if="doc['plan'] === 'standard' && payment_date" icon="cancel" label="決済を停止する" target="_blank" @click="openModal('plandowngrade')"></b-menu-item> -->
          <b-menu-item
            v-if="doc['plan'] === 'standard' && payment_date"
            icon="cancel"
            label="決済を停止する"
            :active="activeMenu('/payment')"
            @click="$router.push('/payment').catch(() => {})"
          ></b-menu-item>
          <b-menu-item v-if="doc['plan'] === 'standard' && !payment_date" icon="star-circle" label="決済を再開する" @click="openModal('restorestandard')"></b-menu-item>
          <b-menu-item icon="close" label="ページの削除" @click="remove()"></b-menu-item>
        </b-menu-list>
      </b-menu>

      <b-menu v-if="!disapproved && (doc['page_id'] || pages.length)" class="box">
        <b-menu-list label="Google広告">
          <b-menu-item icon="google-ads" v-if="adCheckoutMenu" label="Google広告を申込む" target="_blank" @click="openModal('adcheckout')"></b-menu-item>
          <!-- <b-menu-item icon="file-chart" v-if="adReportMenu" label="レポート（広告）の確認" :active="activeMenu('/ads_report')" @click='$router.push( "/ads_report" ).catch(() => {})'></b-menu-item> -->
          <b-menu-item icon="mdi mdi-chart-line" label="問合せ数を増やす方法" :active="activeMenu('/ads_conversion')" @click="$router.push('/ads_conversion').catch(() => {})"></b-menu-item>
          <b-menu-item icon="square-edit-outline" v-if="adDataChangeMenu" label="広告内容の確認" :active="activeMenu('/ads_data')" @click="$router.push('/ads_data').catch(() => {})"></b-menu-item>
          <b-menu-item icon="map-marker-radius" v-if="adDeliveryRangeMenu" label="配信地域の変更" target="_blank" @click="openModal('addeliveryrangechange')"></b-menu-item>
          <b-menu-item icon="credit-card-settings-outline" v-if="adBudgetChangeMenu" label="広告費・決済日の変更" target="_blank" @click="openModal('adbudgetchange')"></b-menu-item>
          <b-menu-item icon="pause-circle-outline" v-if="adStopOrRestartMenu" label="広告の停止・再開" target="_blank" @click="openModal('adstoporrestart')"></b-menu-item>
          <b-menu-item icon="cancel" v-if="adCancelMenu" label="広告の解約" target="_blank" @click="openModal('adcancel')"></b-menu-item>
        </b-menu-list>
      </b-menu>

      <b-menu class="box">
        <b-menu-list label="管理">
          <!-- <b-menu-item icon="account-multiple" :active="activeMenu('/referral')" label="紹介プログラム" @click="$router.push('/referral').catch(() => {})"></b-menu-item> -->
          <!-- <b-menu-item icon="cash" label="支払履歴"></b-menu-item> -->
          <b-menu-item icon="help-circle-outline" :active="activeMenu('/faq')" label="よくある質問" @click="$router.push('/faq').catch(() => {})"></b-menu-item>
          <b-menu-item icon="chat-processing-outline" label="LINEで問合せる" target="_blank" @click="lineLink()"></b-menu-item>
          <b-menu-item icon="email" label="メールアドレスの変更" @click="$router.push('/mailchange').catch(() => {})"></b-menu-item>
          <b-menu-item icon="lock-reset" label="パスワードの変更" @click="$router.push('/passwordchange').catch(() => {})"></b-menu-item>
          <b-menu-item icon="exit-to-app" label="ログアウト" @click="$router.push('/login').catch(() => {})"></b-menu-item>
          <b-menu-item v-if="pages.length === 0" icon="close" label="アカウントの削除" @click="withdrawal()"></b-menu-item>
        </b-menu-list>
      </b-menu>
    </div>
    <b-loading :is-full-page="isFullPage" :active.sync="isLoading" :can-cancel="true"></b-loading>
  </div>
</template>

<script>
import firebase from 'firebase/app';
import 'bulma/css/bulma.css';
import { selectid } from '../pageCreate.js';
import Config from '../config.js';
import Modal from './Modal.vue';
// import { loadStripe } from '@stripe/stripe-js';
import moment from 'moment';
import { Slide } from 'vue-burger-menu';

export default {
  components: {
    Modal,
    Slide,
  },
  props: ['modalstatus'],
  computed: {
    service_name() {
      return this.$store.state.admin_data ? this.$store.state.admin_data.service_name.page.name : '';
    },
    // draftもcomputed
    draft() {
      return this.$store.state.admin_data.default_page_data;
    },
    // Google検索広告の契約中・未契約
    disapproved() {
      if (this.ads_doc && this.ads_doc.disapproved) {
        return true;
      }
      return false;
    },
    // Google検索広告の契約中・未契約
    gsAdsStatus() {
      if (this.charges_doc && this.charges_doc.gs && this.charges_doc.gs.payment_date) {
        return true;
      }
      return false;
    },
    // Googleディスプレイ広告の契約中・未契約
    gdAdsStatus() {
      if (this.charges_doc && this.charges_doc.gd && this.charges_doc.gd.payment_date) {
        return true;
      }
      return false;
    },
    // 有料プランの案内
    standardPlanNotificationMenu() {
      if (this.doc.plan === 'standard' || this.payment_date === '') {
        return false;
      }
      return true;
    },
    // Google広告の案内
    googleAdsNotificationMenu() {
      if (this.charges_doc && this.charges_doc.gs && this.charges_doc.gd && this.charges_doc.gs.payment_date && this.charges_doc.gd.payment_date) {
        return false;
      }
      return true;
    },
    // 代理作成用のメニュー表示
    agentRequestMenu() {
      if (!this.sales_doc || this.sales_doc.page_revision === undefined || this.sales_doc.page_revision === null || this.sales_doc.page_revision === '') {
        return true;
      }
      return false;
    },
    // 広告決済メニュー表示
    adCheckoutMenu() {
      if (this.charges_doc && this.charges_doc.gs && this.charges_doc.gd && this.charges_doc.gs.payment_date && this.charges_doc.gd.payment_date) {
        return false;
      }
      return true;
    },
    // 広告開始・終了メニュー表示
    adStopOrRestartMenu() {
      if (this.charges_doc) {
        if (this.charges_doc.gs && this.charges_doc.gs.payment_date) {
          return true;
        }
        if (this.charges_doc.gd && this.charges_doc.gd.payment_date) {
          return true;
        }
      }
      return false;
    },
    // 地域設定ニュー表示
    adDeliveryRangeMenu() {
      if (this.charges_doc) {
        if (this.charges_doc.gs && this.charges_doc.gs.payment_date) {
          return true;
        }
        if (this.charges_doc.gd && this.charges_doc.gd.payment_date) {
          return true;
        }
        if (this.charges_doc.page && this.charges_doc.page.payment_date) {
          return true;
        }
      }
      return false;
    },
    adCancelMenu() {
      if (this.charges_doc) {
        if (this.charges_doc.gs && this.charges_doc.gs.payment_date) {
          return true;
        }
        if (this.charges_doc.gd && this.charges_doc.gd.payment_date) {
          return true;
        }
      }
      return false;
    },
    adReportMenu() {
      if (this.ads_doc) {
        if (this.ads_doc.gs && this.ads_doc.gs.budget) {
          return true;
        }
        if (this.ads_doc.gd && this.ads_doc.gd.budget) {
          return true;
        }
      }
      return false;
    },
    // 広告費・決済日の変更メニューの表示
    adBudgetChangeMenu() {
      if (this.charges_doc) {
        if (this.charges_doc.gs && this.charges_doc.gs.payment_date) {
          return true;
        }
        if (this.charges_doc.gd && this.charges_doc.gd.payment_date) {
          return true;
        }
      }
      return false;
    },
    adDataChangeMenu() {
      if (this.charges_doc) {
        if (this.charges_doc.gs && this.charges_doc.gs.payment_date) {
          return true;
        }
        if (this.charges_doc.gd && this.charges_doc.gd.payment_date) {
          return true;
        }
      }
      return false;
    },
    // 削除用のswal設定
    paramSwalForRemove() {
      return {
        title: '本当に削除しますか？',
        html: this.doc['business_name'] + '<br>' + 'https://jp.ilb.net/' + this.doc['page_id'] + '<br>' + '<br>' + 'ページの全データが削除されます' + '<br>' + '削除したページは復旧できません',
        icon: 'warning',
        showCancelButton: true,
        cancelButtonText: 'キャンセル',
        confirmButtonColor: '#d33',
        confirmButtonText: '削除する',
      };
    },
    // swal
    swalParam() {
      let swal = this.$swal;
      return {
        title: '本当に削除しますか？',
        html: 'パスワードを入力し「アカウントを削除する」を押してください' + '<br>' + '<br>' + '削除したアカウントは復旧できません',
        // html: 'パスワードを入力し「アカウントを削除する」を押してください' + '<br>' + '<br>' + '削除したアカウントは復旧できません' + '<br>' + '紹介プログラムの権利も消滅します',
        icon: 'warning',
        input: 'text',
        inputAttributes: {
          autocapitalize: 'off',
        },
        showCancelButton: true,
        confirmButtonColor: '#d33',
        confirmButtonText: 'アカウントを削除する',
        cancelButtonText: 'キャンセル',
        focusConfirm: false,
        preConfirm: (input) => {
          if (!input) {
            swal.showValidationMessage('パスワードを入力してください');
          }
        },
      };
    },
    pageUrlCheck() {
      if (
        this.doc['category_group'] &&
        this.doc['category'] &&
        this.doc['business_name'] &&
        this.doc['phone'] &&
        this.doc['email'] &&
        this.doc['zip_code'] &&
        this.doc['region'] &&
        this.doc['city'] &&
        this.doc['catchphrase'] &&
        this.doc['business_image']
      ) {
        return true;
      }
      return false;
    },
  },
  methods: {
    // 入力された日付に31日を加算して返してYMD形式に変換する
    expired(date) {
      return date ? moment(new Date(date).toISOString()).add(31, 'days').format('YYYY-MM-DD') : '';
    },
    /**
     * LINE LINKオープン
     */
    lineLink() {
      open('https://lin.ee/lX4ulJS');
    },
    /**
     * ページ選択前のpage_id取得用
     */
    onFocus() {
      this.beforeSelectedId = this.selectedId;
    },
    /**
     * ページ選択のプルダウン
     */
    async getSelectedIds() {
      try {
        // ページ移動する前の保存アラート
        const swal = await this.$swal({
          title: '変更内容を保存しましたか？',
          text: 'ページを移動すると、未保存の内容は破棄されます。',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'ページを移動する',
          cancelButtonText: 'キャンセル',
        });
        // 移動しない
        if (!swal.value) {
          // 選択前のIDに戻す
          this.selectedId = this.beforeSelectedId;
          return;
        }
      } catch (err) {
        console.log(err);
      }

      // ページ遷移OKなら選択したページ情報を取得
      return Config.COLLECTION.doc(this.selectedId).onSnapshot((p) => {
        if (p.data()) {
          this.doc = p.data();
        } else {
          this.doc = this.draft;
        }
      });
    },
    /**
     * ページ新規作成
     */
    newPage: function () {
      this.activeMenu('');
      // function.jsより
      selectid(this);
    },
    /**
     * ドキュメントIDの新規発行
     */
    getMaxDocumentId: async function () {
      let maxLpNo = [];

      try {
        const snapshot = await Config.COLLECTION.orderBy('page_id', 'desc').limit(3).get();
        await snapshot.docs.map((doc) => {
          // doc.idが数字だった場合
          if (!isNaN(doc.id)) {
            maxLpNo.push(doc.id);
          }
        });
        return ('000000000' + (Number(Math.max.apply(null, maxLpNo)) + 1)).slice(-9);
      } catch (err) {
        console.log(err);
      }
    },
    /**
     * ページ新規作成時の保存ボタン
     */
    async submit(p, agentRequest = false) {
      // loading
      this.isLoading = true;
      let swal = this.$swal;

      // 新規作成
      // page_id兼ドキュメントID発行
      let newId = await this.getMaxDocumentId();
      p.page_id = newId;

      // firestoreに新規ページ作成
      try {
        await Config.COLLECTION.doc(newId).set(p);
        // 保存後のデータ取得
        await this.listenData(newId);
        await this.$store.commit('page_id', newId);

        // 新規作成した際のメール送信
        // await this.newPageCreateMail()

        // // 代理作成用のsales_dataを作成
        // await Config.SALES_COLLECTION.doc(newId).set(
        //   {
        //     date: moment(moment().unix(), 'X').format(),
        //     page_revision: agentRequest || '',
        //     uid: this.$store.state.user.uid,
        //   },
        //   { merge: true }
        // );

        // // sales_data取得
        // await this.listenSalesData(newId);

        // // 代理入力依頼した時のメール
        // if (agentRequest) {
        //   await this.agentRequestMail();
        // }

        //新規作成完了swal
        const result = await swal.fire({
          title: 'ページ新規作成完了',
          icon: 'success',
          html:
            '<p>' +
            this.doc['business_name'] +
            ' ' +
            this.doc['branch_name'] +
            '</p>' +
            '<p class="pb-2">https://jp.ilb.net/' +
            this.doc['page_id'] +
            '</p>' +
            '<p class="has-text-danger">現在ページは非公開です</p>' +
            '<p class="help mb-2">ページを公開するためには、月額利用料を決済してください</p>' +
            (agentRequest ? '<br>' + '<p class="pb-2">代理入力が完了しましたら、メールにてご報告します。</p>' : ''),
          confirmButtonText: '決済画面に移動する',
          cancelButtonText: '閉じる',
        });

        // 決済画面に移動
        console.log('result', result);
        if (result.isConfirmed) {
          await this.$router.push('/payment').catch((err) => {
            console.log(err);
          });
          return;
        }

        // pagesを更新して作成したIPを選択済みにする
        if (this.$route.path !== '/') {
          await this.$router.push('/').catch((err) => {
            console.log(err);
          });
          return;
        }
      } catch (e) {
        swal('error', '保存エラー：' + e, 'error');
        // loading
        this.isLoading = false;
        return;
      }
      // loading
      this.isLoading = false;
    },
    // /**
    //  * 代理入力依頼のメール送信
    //  */
    // async agentRequestMail() {
    //   // メール送信処理（sendMailTarget）:管理者へ
    //   let data = {
    //     template_id: 'AGENT_REQUEST_CREATE_PAGE',
    //     uid: this.$store.state.user.uid,
    //     page_id: this.doc['page_id'],
    //     business_name: this.doc['business_name'],
    //     branch_name: this.doc['branch_name'] || null,
    //     phone: this.doc['phone'],
    //     application_route: '管理画面',
    //     status: 'sending',
    //     date: moment().format('YYYY-MM-DD HH:MM:SS'),
    //     email: this.doc['email'] || this.$store.state.user.email,
    //   };
    //   await Config.SENDMAIL_TARGET_COLLECTION.add(data);
    // },
    /**
     * Firestoreよりpage_data（ページデータ）取得
     * ページ選択プルダウンに設定
     */
    async listenData(id = null) {
      let querySnapshot;
      try {
        querySnapshot = await Config.COLLECTION.where('uid', '==', this.$store.state.user.uid).where('deleted', '==', 'false').get();

        if (!querySnapshot.size) {
          // ログインのみでデータがない
          this.display = false;
          // faqページへ
          if (this.$route.path === '/main') {
            this.$router.push('faq').catch((err) => {
              console.log(err);
            });
          }
          return;
        }
      } catch (err) {
        console.log(err);
        return;
      }

      let pages = [];
      let ids = [];
      let doc = {};
      querySnapshot.forEach((p) => {
        pages.push({
          id: p.id,
          business_name: p.data().business_name,
          category: p.data().category,
          branch_name: p.data().branch_name,
        });
        doc[p.id] = p.data();
        ids.push(p.id);
      });

      // pagesにセレクター用のデータ入れる
      this.pages = pages;

      // 不正page_id対策
      if (!doc[id]) id = null;

      // id指定の場合には指定されたidを選択しそのデータを表示させる
      if (id) {
        this.selectedId = id;
        this.doc = doc[id];
        // charges_data取得(last_payment,payment_date用)
        this.listenChargesData(id);
        // ads_data取得
        this.listenAdsData(id);
        // sales_data取得
        this.listenSalesData(id);
        // id指定がない場合には最新版を選択しそのデータを表示させる
      } else {
        this.selectedId = pages[0].id;
        this.doc = doc[ids[0]];
        // charges_date取得(last_payment,payment_date用)
        this.listenChargesData(ids[0]);
        // ads_date取得
        this.listenAdsData(ids[0]);
        // sales_data取得
        this.listenSalesData(ids[0]);
      }
      // 更新不要のデータ項目を除く
      // Config.EXCEPT_KEYS.forEach( key => delete this.doc[key])

      // 必須項目が全て入力されていたらレポートページ表示
      if (
        this.$route.path === '/main' &&
        this.doc['business_name'] &&
        this.doc['category_group'] &&
        this.doc['category'] &&
        this.doc['phone'] &&
        this.doc['email'] &&
        this.doc['zip_code'] &&
        this.doc['region'] &&
        this.doc['city'] &&
        this.doc['street_address']
      ) {
        this.$router.push('/page_report').catch((err) => {
          console.log(err);
        });
      }
    },
    /**
     * Firestoreよりcharges_data（決済関連）取得
     */
    async listenChargesData(id) {
      try {
        let charges = await Config.CHARGES_COLLECTION.doc(id).get();
        this.charges_doc = await charges.data();

        // emitでデータを渡す
        this.$emit('getChargesDoc', this.charges_doc);

        if (this.charges_doc && this.charges_doc.page) {
          // 画面上で利用する決済日
          this.payment_date = (await this.charges_doc.page.payment_date) ? this.charges_doc.page.payment_date : '';
          // 画面上で利用する最終決済日
          this.last_payment_date = (await this.charges_doc.page.last_payment_date) ? this.charges_doc.page.last_payment_date : '';
          //
          this.expiration_date = moment(this.last_payment_date).add(31, 'days').format('YYYY年MM月DD日');
        }
      } catch (err) {
        console.debug('[chargesデータerr]', err);
      }
    },
    /**
     * Firestoreよりads_data（広告関連）取得
     */
    async listenAdsData(id) {
      try {
        let ads = await Config.ADS_COLLECTION.doc(id).get();
        this.ads_doc = await ads.data();
      } catch (err) {
        console.debug('[adsデータerr]', err);
      }
    },
    /**
     * Firestoreよりsales_data（営業関連）取得
     */
    async listenSalesData(id) {
      try {
        let sales = await Config.SALES_COLLECTION.doc(id).get();
        this.sales_doc = await sales.data();
      } catch (err) {
        console.debug('[salesデータerr]', err);
      }
    },
    /**
     * Firestoreよりadmin_data（サービス共通データ）取得
     */
    async listenAdminData() {
      try {
        let admin_doc = {};
        const snapshot = await Config.ADMIN_COLLECTION.get();

        if (!snapshot.size) {
          let result = await this.$swal.fire({
            title: 'エラー',
            text: 'サービスデータの取得に失敗しました。再度、ログインし直してください。',
            icon: 'error',
          });

          if (result.value) {
            // loginページへ
            this.$router.push('login').catch((err) => {
              console.log(err);
            });
            return;
          }
        }

        snapshot.forEach((p) => {
          admin_doc[p.id] = p.data();
        });

        await this.$set(admin_doc.default_page_data, 'uid', this.$store.state.user.uid);
        await this.$store.commit('admin_data', admin_doc);
        this.admin_doc = await admin_doc;
      } catch (err) {
        console.log(err);
        return;
      }
    },
    /**
     * firestoreよりタイトル用のparamを取得
     */
    async getParam() {
      await Config.COLLECTION.doc('param').onSnapshot((p) => {
        if (p.data()) {
          this.title = p.data();
        }
      });
    },
    /**
     * モーダルオープン共通関数
     */
    openModal(type) {
      // 自分で作るパターン
      this.modal = true;
      this.type = type;
    },
    /**
     * モーダルクローズ共通関数
     */
    closeModal() {
      this.modal = false;
      // データ再取得
      this.listenData(this.$store.state.page_id);
    },
    /**
     * モーダル変更（塗り替わり）共通関数
     */
    changeModal(type) {
      // いったんモーダル閉じる
      this.closeModal();
      this.modal = true;
      this.type = type;
    },
    /**
     * サイドバーメニューアクティブ化
     *
     * @param menu タグ内に仕込んだアクティブ比較用のメニューシンボル
     * @param from ページ遷移前のpath（vue routerで利用）
     * @param to   ページ遷移後のpath（vue routerで利用）
     */
    activeMenu(menu, from = null, to = null) {
      if (!(menu instanceof Array)) {
        menu = [menu];
      }
      if (menu.indexOf(this.$route.path) !== -1) {
        // routerからページ遷移しない選択時
        if (this.$route.path === '/' && to !== '/' && from === '/') {
          // 掲載情報のメニュをactive化 TODO:elem直接してのため改善の余地あり
          document.getElementsByClassName('menu-list')[0].children[0].children[0].click();
          return false;
        }
        return true;
      }
      return false;
    },
    /**
     * 退会処理
     */
    async withdrawal() {
      // すべてのIPが存在しないか確認
      if (this.pages.length) {
        await this.$swal('warning', '全てのページを削除してください', 'warning');
        return;
      }
      // loading
      this.isLoading = true;

      let result = await this.$swal.fire(this.swalParam);
      if (!result.isConfirmed) {
        this.isLoading = false;
        return;
      }
      // 再認証
      const user = await Config.FIREBASE_AUTH.currentUser;
      // 再認証のパラメーター
      const credential = await firebase.auth.EmailAuthProvider.credential(user.email, result.value);
      // ユーザー再認証
      try {
        await user.reauthenticateWithCredential(credential);
      } catch (error) {
        await this.$swal('エラー', 'パスワードが異なります', 'error');
        this.isLoading = false;
        return;
      }
      // ユーザー削除
      try {
        // referral_data のdeleted が true
        await Config.REFERRAL_COLLECTION.doc(user.uid).set({ deleted: 'true' }, { merge: true });
        const userInvalidation = await Config.FIREBASE_FUNCTIONS.httpsCallable('userInvalidation');
        result = await userInvalidation(user.uid);

        await console.debug('[ユーザー無効化]', JSON.stringify(result));
        if (!result.data.status) {
          throw new Error(result.data.error);
        }
        // console.debug('[user delete success]')
        await this.$swal('アカウントを削除しました', 'ご利用いただき、ありがとうございました', 'success');
        await this.$router.push('/login').catch((err) => {
          console.log(err);
        });
      } catch (error) {
        await this.$swal('アカウントを削除できませんでした', '再度、アカウントの削除を実施してください', 'error');
        this.isLoading = false;
        return;
      }
      this.isLoading = false;
      return;
    },
    /**
     * ページ削除
     */
    async remove() {
      let page_id = this.doc['page_id'];
      let result = await this.$swal.fire(this.paramSwalForRemove);
      if (result.value) {
        try {
          // page_dataのdelete
          await Config.COLLECTION.doc(page_id).set(
            {
              date: moment(moment().unix(), 'X').format(),
              deleted: 'true',
              original_id: '',
            },
            { merge: true }
          );
          // charges_dataのdeleted
          await Config.CHARGES_COLLECTION.doc(page_id).set({ deleted: 'true' }, { merge: true });
          await this.imageDirRemove(page_id);
          await location.reload();
        } catch (e) {
          this.$swal('error', '削除エラー：' + e, 'error');
        }
      }
    },
    /**
     * ページ削除処理時のfire storageのディレクトリ削除
     */
    async imageDirRemove(selectedId) {
      let path = Config.STORAGE_DIR + '/' + selectedId;
      let ref = Config.FIREBASE_STORAGE.ref(path);
      let dir = await ref.listAll();
      await dir.items.forEach(async (fileRef) => {
        await fileRef.delete();
      });
      await dir.prefixes.forEach(async (folderRef) => {
        await this.deleteFolderContents(folderRef.fullPath);
      });
    },
    /**
     * 画面リサイズ発火
     */
    handleResize() {
      // resizeのたびにこいつが発火する
      this.window_size = window.innerWidth;
    },
    /**
     * ブラウザ閉じる操作時のエラーメッセージ（IEのみ）
     */
    confirmSave(event) {
      event.returnValue = '編集中のものは保存されませんが、よろしいですか？';
    },
    /**
     * page切替時のキャンバス画像削除
     */
    ImageRefResetAll() {
      Object.keys(this.$refs).map((imageTitle) => {
        if (Array.isArray(this.$refs[imageTitle.replace(/_/g, '').replace(/[0-9]/g, '')])) {
          this.$refs[imageTitle.replace(/_/g, '').replace(/[0-9]/g, '')].forEach((component) => {
            component.reset();
          });
          return;
        }
        if (this.$refs[imageTitle.replace(/_/g, '').replace(/[0-9]/g, '')]) {
          this.$refs[imageTitle.replace(/_/g, '').replace(/[0-9]/g, '')].reset();
        }
      });
    },
    /**
     * オリジナルIDの重複チェック
     */
    async duplicationCheck() {
      if (!this.original_id) {
        return;
      }
      const pageDataSnap = await Config.COLLECTION.where('page_id', '!=', this.doc['page_id']).where('original_id', '==', this.original_id).get();

      // 件数カウント
      this.duplicateID = pageDataSnap.size;
    },
    /**
     * オリジナルIDだけ保存（ボタンが押せるときのみ保存）
     */
    async saveOriginalID() {
      const page_id = this.selectedId;

      try {
        await Config.COLLECTION.doc(page_id).set(
          {
            original_id: this.original_id.trim(),
            date: moment(moment().unix(), 'X').format(),
          },
          { merge: true }
        );
      } catch (e) {
        await this.$swal('error', '保存エラー：' + e, 'error');
        return;
      }

      await this.$swal.fire({
        html:
          '転送用URLを設定しました。' +
          '<BR><BR>' +
          '転送用URL' +
          '<BR>' +
          '<a href="' +
          Config.DESTINATION_URL +
          '/' +
          this.original_id +
          '" target="_BLANK">' +
          Config.DESTINATION_URL +
          '/' +
          this.original_id +
          '</a>',
        icon: 'success',
      });
      await this.listenData(page_id);
    },
  },
  data() {
    return {
      id: '',
      modal: false,
      type: '',
      message: '',
      referral_flg: '',
      window_size: window.innerWidth,
      title: [],
      doc: [],
      ads_doc: {},
      charges_doc: {},
      sales_doc: {},
      admin_doc: {},
      selectedId: this.$store.state.page_id,
      pages: [],
      payment_date: '',
      last_payment_date: '',
      expiration_date: '',
      isLoading: false,
      isFullPage: true,
      beforeSelectedId: this.$store.state.page_id, // プルダウン選択前のID取得用
      original_id: this.doc && this.doc['original_id'] ? this.doc['original_id'] : '',
      duplicateID: 0,
      regex_original_id: /^[0-9a-zA-Z-]*$/,
      customMessageOriginalId: '半角英数字、記号「ｰ」で入力してください',
    };
  },
  watch: {
    modalstatus: function (val) {
      console.debug('[modalstaus]', val);
      this.listenData(this.$store.state.page_id);
    },
    selectedId: function (val) {
      // function.jsより
      //selectid(val, this)
      this.doc['page_id'] = val;
      this.$emit('getdoc', this.doc);
      this.$emit('gettitle', this.title);
      this.$store.commit('page_id', val);
      this.listenChargesData(val);
      this.listenAdsData(val);
      this.listenSalesData(val);
      // ** page切替時にはキャンバス全リセット
      // ** また、Main.vue上でimageDataのリセット
      this.ImageRefResetAll();

      // ページ変更の際にoriginal_idにセット
      this.original_id = this.doc['original_id'];
    },
    doc: function (val) {
      // function.jsより
      //selectid(val, this)
      this.selectedId = val['page_id'];
      this.$emit('getdoc', this.doc);
      this.$emit('gettitle', this.title);
      this.$store.commit('page_id', val['page_id']);

      // ページ変更の際にoriginal_idにセット
      this.original_id = this.doc['original_id'];

      // paymentを配列に入れ直す
      if (!Array.isArray(this.doc['payment'])) {
        this.$set(this.doc, 'payment', []);
      }

      // service_keywordsにnull文字入れ直す
      let service_keywords_array = [];
      if (!this.doc['service_keywords']) this.doc['service_keywords'] = [];
      for (let i = 0; i < 10; i++) {
        if (!this.doc['service_keywords'][i]) {
          service_keywords_array[i] = '';
        } else {
          service_keywords_array[i] = this.doc['service_keywords'][i];
        }
      }
      this.$set(this.doc, 'service_keywords', service_keywords_array);

      // serviceにnull文字入れ直す
      let service_array = [];
      if (!this.doc['service']) this.doc['service'] = {};
      for (let i = 0; i < 10; i++) {
        service_array[i] = this.doc['service'][i]
          ? this.doc['service'][i]
          : {
              detail: '',
              price: '',
              tag: '',
              title: '',
              image: '',
              url: '',
            };
      }
      this.$set(this.doc, 'service', service_array);

      // featureにnull文字入れ直す
      let feature_array = [];
      if (!this.doc['feature']) this.doc['feature'] = {};
      for (let i = 0; i < 5; i++) {
        feature_array[i] = this.doc['feature'][i]
          ? this.doc['feature'][i]
          : {
              title: '',
              image: '',
              detail: '',
            };
      }
      this.$set(this.doc, 'feature', feature_array);

      // qaにnull文字入れ直す
      let qa_array = [];
      if (!this.doc['qa']) this.doc['qa'] = {};
      for (let i = 0; i < 5; i++) {
        qa_array[i] = this.doc['qa'][i]
          ? this.doc['qa'][i]
          : {
              question: '',
              answer: '',
              movie: '',
            };
      }
      this.$set(this.doc, 'qa', qa_array);

      // vocにnull文字入れ直す
      let voc_array = [];
      if (!this.doc['voc']) this.doc['voc'] = {};
      for (let i = 0; i < 5; i++) {
        voc_array[i] = this.doc['voc'][i]
          ? this.doc['voc'][i]
          : {
              data: '',
              detail: '',
              image: '',
              title: '',
            };
      }
      this.$set(this.doc, 'voc', voc_array);

      // caseにnull文字入れ直す
      let case_array = [];
      if (!this.doc['case']) this.doc['case'] = {};
      for (let i = 0; i < 5; i++) {
        case_array[i] = this.doc['case'][i]
          ? this.doc['case'][i]
          : {
              data: '',
              detail: '',
              image1: '',
              image2: '',
              title: '',
              url: '',
            };
      }
      this.$set(this.doc, 'case', case_array);

      // feedにnull文字入れ直す
      let feed_array = this.doc['feed'] || [];
      let feed_type = feed_array.map((feed) => feed.type);
      if (feed_type.indexOf('facebook_page') === -1) {
        feed_array.push({
          display: true,
          type: 'facebook_page',
        });
      }

      if (feed_type.indexOf('x') === -1) {
        feed_array.push({
          display: true,
          type: 'x',
        });
      }
      this.$set(this.doc, 'feed', feed_array);
    },
  },
  async created() {
    console.log('created', 'Menu.vue');
    // admin_docはサービス共通のためlistenDataではとらずここで取る。
    await this.listenAdminData();
    // paramを取得
    await this.getParam();

    // routerで受け取ったpage_id
    const regex = /[0-9]{9}/;
    if (regex.test(this.$route.params.id) && this.$route.params.id) {
      await this.listenData(this.$route.params.id);
    } else {
      await this.listenData(this.$store.state.page_id);
    }

    if (this.$route.name) {
      switch (this.$route.name) {
        case 'newPage':
          this.newPage();
          break;
        default:
          await this.openModal(this.$route.name);
          break;
      }
    }

    // // 決済用stripeの初期化
    // this.stripe = await loadStripe(Config.PUBLIC_KEY);

    // ブラウザ挙動のアラート
    window.addEventListener('beforeunload', this.confirmSave);
    window.addEventListener('resize', this.handleResize);
  },
  async mounted() {},
  // destroyed は Vue インスタンスの破棄した後 に実行されるフックなので､そこでやろうとしても 遅すぎる ため
  beforeDestroy() {
    window.removeEventListener('beforeunload', this.confirmSave);
    window.removeEventListener('resize', this.handleResize);
  },
};
</script>

<style>
input[readonly='readonly'] {
  background-color: #c0c0c0;
  color: #666;
}

.swal2-styled.swal2-confirm {
  width: inherit;
}

.swal2-styled.swal2-cancel {
  width: inherit;
}

.bm-burger-button {
  /*      position: fixed;*/
  position: sticky !important;
  width: 36px;
  height: 30px;
  left: 36px;
  top: 36px;
  cursor: pointer;
}

.bm-burger-bars {
  background-color: #373a47;
}

.line-style {
  position: absolute;
  height: 20%;
  left: 0;
  right: 0;
}

.cross-style {
  position: absolute;
  top: 12px;
  right: 2px;
  cursor: pointer;
}

.bm-cross {
  background: #bdc3c7;
}

.bm-cross-button {
  height: 24px;
  width: 24px;
}

.bm-menu {
  height: 100%;
  /* 100% Full-height */
  width: 0;
  /* 0 width - change this with JavaScript */
  position: fixed;
  /* Stay in place */
  z-index: 1000;
  /* Stay on top */
  top: 0;
  left: 0;
  background-color: rgb(248, 247, 247) !important;
  /*  background-color: rgb(63, 63, 65);  Black*/
  overflow-x: hidden;
  /* Disable horizontal scroll */
  padding-top: 60px;
  /* Place content 60px from the top */
  transition: 0.5s;
  /*0.5 second transition effect to slide in the sidenav*/
}

.bm-overlay {
  /*  background: rgba(0, 0, 0, 0.3); */
  background: rgba(0, 0, 0, 0) !important;
}

.bm-item-list {
  color: #b8b7ad;
  margin-left: 10%;
  font-size: 20px;
}

.bm-item-list > * {
  display: flex;
  text-decoration: none;
  padding: 0.7em;
}

.bm-item-list > * > span {
  margin-left: 10px;
  font-weight: 700;
  color: white;
}

.navbar-brand,
.navbar-tabs {
  min-height: 5.25rem !important;
  padding-left: 1rem !important;
}

html.has-navbar-fixed-top,
body.has-navbar-fixed-top {
  padding-top: 0rem !important;
}

@media screen and (max-width: 770px) {
  .mobile-margin {
    margin-top: 2rem !important;
  }
}

.notification-card-agent-request {
  margin-bottom: 10px;
  padding: 10px;
  background-color: #f5f5f5;
  color: #4a4a4a;
}

.notification-card-standard-plan {
  margin-bottom: 10px;
  padding: 10px;
  background-color: #f5f5f5;
  color: #4a4a4a;
}

.notification-card-google-ads {
  margin-bottom: 10px;
  padding: 10px;
  background-color: #f5f5f5;
  color: #4a4a4a;
}

.notification-referral {
  margin-bottom: 10px;
  padding: 10px;
  background-color: #f5f5f5;
  color: #4a4a4a;
}

.card-content-id-form {
  background-color: transparent;
  padding: 0.7rem;
}

.field-label.is-normal {
  margin-right: 0.2rem;
}
</style>
