<template>
  <transition name="modal" appear>
    <div class="modal modal-overlay" @click.self="type === 'agentrequest' ? '' : $emit('close')">
      <div class="modal-window">
        <div v-if="type === 'checkout'" class="modal-content">
          <Checkout :pagedata="pagedata" :salesdata="salesdata" :admindata="admindata" @modalclose="$emit('close')" @changeModalAgentRequest="$emit('changeModalAgentRequest')" />
        </div>
        <div v-if="type === 'cardchange'" class="modal-content">
          <CardChange :pagedata="pagedata" @modalclose="$emit('close')"/>
        </div>
        <div v-if="type === 'qrlink'" class="modal-content">
          <QrLink :pagedata="pagedata" @modalclose="$emit('close')"/>
        </div>
        <div v-if="type === 'adcheckout'" class="modal-content">
          <AdCheckout v-if="pagedata['plan'] === 'standard'" :pagedata="pagedata" :chargesdata="chargesdata" :adsdata="adsdata" @modalclose="$emit('close')" @changeModalCardchange="$emit('changeModalCardchange')" />
          <IntroStandardPlan v-if="pagedata['plan'] === 'free'" :pagedata="pagedata" @modalclose="$emit('close')" @checkout="$emit('checkout')" />
        </div>
        <div v-if="type === 'addeliveryrangechange'" class="modal-content">
          <AdDeliveryRangeChange :pagedata="pagedata" :adsdata="adsdata" :chargesdata="chargesdata" @modalclose="$emit('close')" />
        </div>
        <div v-if="type === 'adbudgetchange'" class="modal-content">
          <AdBudgetChange :pagedata="pagedata" :adsdata="adsdata" :chargesdata="chargesdata" @modalclose="$emit('close')" @changeModalCardchange="$emit('changeModalCardchange')"/>
        </div>
        <div v-if="type === 'adstoporrestart'" class="modal-content">
          <AdStopOrRestart :pagedata="pagedata" :adsdata="adsdata" :chargesdata="chargesdata" @changeModalAdBudgetChange="$emit('changeModalAdBudgetChange')" @modalclose="$emit('close')" />
        </div>
        <div v-if="type === 'agentrequest'" class="modal-content">
          <AgentRequest :pagedata="pagedata" @modalclose="$emit('close')" />
        </div>
        <div v-if="type === 'adcancel'" class="modal-content">
          <AdCancel :pagedata="pagedata" :adsdata="adsdata" :chargesdata="chargesdata" @modalclose="$emit('close')" />
        </div>
        <div v-if="type === 'restorestandard'" class="modal-content">
          <RestoreStandard :pagedata="pagedata" :chargesdata="chargesdata" admindata="admindata" @modalclose="$emit('close')" />
        </div>
        <div v-if="type === 'plandowngrade'" class="modal-content">
          <PlanDowngrade :pagedata="pagedata" :chargesdata="chargesdata" @modalclose="$emit('close')" />
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import Checkout from './stripe/Checkout.vue'
import CardChange from './stripe/CardChange.vue'
import QrLink from './share/QrLink.vue'
import AdCheckout from './stripe/AdCheckout.vue'
import IntroStandardPlan from './ad/IntroStandardPlan.vue'
import AdBudgetChange from './ad/AdBudgetChange.vue'
import AdStopOrRestart from './ad/AdStopOrRestart.vue'
import AdDeliveryRangeChange from './ad/AdDeliveryRangeChange.vue'
import AgentRequest from './sales/AgentRequest.vue'
import AdCancel from './ad/AdCancel.vue'
import RestoreStandard from './stripe/RestoreStandard.vue'
import PlanDowngrade from './stripe/PlanDowngrade.vue'

export default {
  props: ['pagedata','type', 'adsdata', 'chargesdata', 'salesdata', 'admindata'],
  components: {
    Checkout,
    CardChange,
    QrLink,
    AdCheckout,
    IntroStandardPlan,
    AdBudgetChange,
    AdStopOrRestart,
    AgentRequest,
    AdCancel,
    AdDeliveryRangeChange,
    RestoreStandard,
    PlanDowngrade,
  },
}
</script>

<style scoped>
.modal .modal-content {
    width: inherit;
    padding: 10px 20px;
}
.modal.modal-overlay {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  z-index: 30;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
}
.modal-window {
  background: #fff;
  border-radius: 4px;
  overflow: hidden;
}
.modal-footer {
  background: #ccc;
  padding: 10px;
  text-align: right;
}

.modal-enter-active, .modal-leave-active {
  transition: opacity 0.4s;
}
.modal-enter-active .modal-window, .modal-leave-active .modal-window {
  transition: opacity 0.4s, transform 0.4s;
}

.modal-leave-active {
  transition: opacity 0.6s ease 0.4s;
}

.modal-enter, .modal-leave-to {
  opacity: 0;
}
.modal-enter .modal-window, .modal-leave-to .modal-window {
  opacity: 0;
  transform: translateY(-20px);
}
</style>
