<template>
  <form @submit.prevent="validateForm">
    <section class="row payment-form">
        <h5>
          <b>
            Google広告を申込む
          </b>
        </h5>
        <p class="has-text-centered service-title page-link">
          広告の着地先URL<br>
          {{ pagedata['business_name'] }} {{ pagedata['branch_name'] }}<br>
          <a :href="'https://jp.ilb.net/' + pagedata['page_id'] + '/'" target="_blank">
              https://jp.ilb.net/{{ pagedata['page_id'] }}/
          </a>
        </p>
        <div v-if="disapproved">
          <p class="has-text-danger">
            Googleによる広告審査が不承認となるため、
          </p>
          <p class="has-text-danger">
            Google広告のお申込はできません。
          </p>
        </div>
        <div v-else>

          <b>広告の種類</b>
          <p class="ads-type-description">
            <a href="https://r.ilb.net/ad-google-ja" target="_blank">
                Google広告の種類について<b-icon icon="launch" size="is-small"></b-icon></a>
          </p>
          <p class="is-size-7">広告の種類を選択してください</p>
          <p class="is-size-7 ads-type-description">各広告ごとに申込（決済）が必要です</p>
          <p class="service-type">
            <b-radio
              v-if="gsDisable"
              v-model="serviceName"
              name="希望の広告"
              native-value="gs"
              disabled
              >
                Google広告（検索）
            </b-radio>
            <b-radio
              v-else
              v-model="serviceName"
              name="希望の広告"
              native-value="gs"
              v-validate="'required'"
              >
                Google広告（検索）
              </b-radio>
            <b-radio
              v-if="gdDisable"
              v-model="serviceName"
              name="希望の広告"
              native-value="gd"
              disabled
            >
              Google広告（ディスプレイ）
            </b-radio>
            <b-radio
              v-else
              v-model="serviceName"
              name="希望の広告"
              native-value="gd"
              v-validate="'required'"
            >
                Google広告（ディスプレイ）
            </b-radio>
          <span class="help is-danger" v-show="errors.has('広告の種類')">{{ errors.first('広告の種類') }}</span>
          </p>

          <b>広告費</b>
          <!-- <p class="is-size-7">半角数字で入力してください</p> -->

          <b-field grouped>
            <!-- <b-input
              size="is-medium"
              placeholder="1"
              v-model="budgetNum"
              name="広告費"
              v-validate="'numeric|max_value:99|required'"
              >
            </b-input> -->
            <b-select placeholder="変更なし" v-model="budgetNum">
              <option
                  v-for="(budget, i) in budgetList"
                  :value="budget"
                  :key="i">
                  {{ budget }}
              </option>
            </b-select>
            <p class="service-price">
              万円
            </p>
          </b-field>
          <span class="help is-danger">広告費は約1ヶ月間で使用します。</span>
          <br>
          <span class="help is-danger" v-show="errors.has('広告費')">{{ errors.first('広告費') }}</span>
          <p class="pt-2">
            <b><u class="has-text-danger"><span class="is-size-5 has-text-danger">決済金額：{{amount.toLocaleString()}}円（税込）</span></u></b>
          </p>
          <div class="box price-box">
            <b>内訳</b><br>
            広告費：{{budget.toLocaleString()}}円<br>
            運用代行費：{{fee.toLocaleString()}}円（広告費の20%）<br>
            10％対象消費税：{{tax.toLocaleString()}}円<br>
          </div>

          <div>
            <p class="has-text-centered"><a href="https://r.ilb.net/tos-ja" target="_blank" rel="noopener">利用規約を見る<b-icon icon="launch" size="is-small"></b-icon></a></p>

            <p class="has-text-centered is-size-7">決済後のキャンセルはできませんのでご注意ください</p>
            <div class="col s12 place-order-button-block">
                <b-button
                  v-if='success === false'
                  class="btn col s12 #e91e63 pink"
                  type="is-success has-text-weight-bold"
                  @click="validateForm"
                  :disabled="errors.any() || !isFormCompleted"
                  >
                    利用規約に同意の上、決済する
                </b-button>
                <b-button v-if="success === 'loading'" loading>Loading</b-button>
                <b-button v-if='success === true' class="btn col s12 #e91e63 pink" @click="modalClose">閉じる</b-button>
                <b-loading :is-full-page="isFullPage" :active.sync="isLoading"></b-loading>
            </div>
            <p class="help has-text-centered mt-0">
                1ヶ月間ごとの自動更新となります
            </p>
          </div>

          <hr>
          <div class="service-title">
          <p class="has-text-centered">
            決済に使用するカード情報<br>
            ●●●● {{ last4 }}（{{ brand }}）
          </p>
          <p class="has-text-centered">
            <a @click="changeModal('changeModalCardchange')">カード情報を変更する</a>
          </p>
        </div>
      </div>
      <p class="mt-2 has-text-centered">
        <a href="#" @click="$emit('modalclose')">管理画面に戻る</a>
      </p>

    </section>
  </form>
</template>

<script>
import Config from '../../config.js'
import moment from 'moment'

export default {
    props: ['pagedata', 'chargesdata','adsdata'],
    computed: {
      last4 () {
        return this.chargesdata.last4
      },
      brand () {
        return this.chargesdata.brand
      },
      customer_id () {
        return this.chargesdata.customer_id
      },
      gsDisable() {
        if (this.chargesdata.gs && this.chargesdata.gs.payment_date) {
          return true
        }
        return false
      },
      gdDisable() {
        if (this.chargesdata.gd && this.chargesdata.gd.payment_date) {
          return true
        }
        return false
      },
      budget () {
        const regex = new RegExp(/\d+?$/);
        // 判定
        if (regex.test(this.budgetNum)) {
            return this.budgetNum * 10000
        } else {
          return 0
        }
      },
      fee () {
        return this.budget * Config.FEE_RATE
      },
      tax() {
        return (this.budget + this.fee) * Config.TAX_RATE
      },
      amount() {
        return this.budget + this.fee + this.tax
      },
      isFormCompleted: function() {
        if (
          !this.serviceName ||
          !this.budgetNum
        ) {
          return false
        }
        return true
      },
      disapproved () {
        return this.adsdata ? this.adsdata.disapproved : false
      },

    },
    data() {
        return {
            budgetNum:1,
            serviceName: null,
            success:false,
            isLoading: false,
            isFullPage: true,
            budgetList : [...Array(100)].map((_, i) => i + 1)
        }
    },
    // TODO:セレクトボックスのしたので削除予定
    async mounted() {
      const dict = {
        custom: {
          '広告費': {
            'numeric': '半角数字のみ入力できます',
            'max_value':'100未満の半角数字を入力してください'
          },
        }
      };
      this.$validator.localize('ja', dict);

      this.$validator.validateAll()
    },
    methods: {
      // 決済するボタン
      async placeOrderButtonPressed() {
        this.success = 'loading'
        this.isLoading = true
        await this.chargeStripe(this.serviceName)
      },
      // stripe決済処理
      async chargeStripe(serviceName) {
          const checkOut = Config.FIREBASE_FUNCTIONS.httpsCallable('instantPaymentForAd')

          let checkout_data = {}
          checkout_data['page_data'] = this.pagedata
          checkout_data['service_name'] = serviceName
          checkout_data['budget'] = this.budget
          checkout_data['amount'] = this.amount

          try {
              let result = await checkOut(checkout_data)
              if (result.data.status) {
                // 広告利用の案内メールを送る
                await this.adFirstAnnounceMail(serviceName)

                await this.$swal({
                  title:'決済が完了しました',
                  html:this.$store.state.admin_data.service_name[serviceName].name
                    + '<br>月額' + this.amount.toLocaleString() + '円（税込）'
                    + '<br><br>広告配信はGoogleでの審査完了後（1〜3営業日）に開始されます。',
                  confirmButtonText: '管理画面に戻る',
                  icon:'success',
                })
                await this.$emit('modalclose')
              } else {
                await this.$swal({
                    title:'決済がエラーとなりました',
                    html: result.data.error.code
                          + '<br>'
                          + result.data.error.message,
                    confirmButtonText: '管理画面に戻る',
                    icon:'error',
                })
                await this.$emit('modalclose')
              }
          } catch (e){
            await this.$swal({
                title:'決済がエラーとなりました',
                text:'管理者に連絡してください:' + e.message,
                confirmButtonText: '管理画面に戻る',
                icon:'error',
            })
            await this.$emit('modalclose')
          }
        this.success = true
        this.isLoading = false
      },
      async adFirstAnnounceMail(serviceName) {
        let data = {
          template_id : 'AD_FIRST_ANNOUNCE',
          to: this.pagedata.email,
          email_auth: this.$store.state.user.email,
          business_name: this.pagedata.business_name,
          branch_name: this.pagedata.branch_name || '',
          category: this.pagedata.category,
          page_id: this.$store.state.page_id,
          budget: this.budget.toLocaleString(),
          delivery_range: Config.DELIVERY_RANGE,
          name: this.$store.state.admin_data.service_name[serviceName].name,
          short_name: this.$store.state.admin_data.service_name[serviceName].short_name,
          system_name: this.$store.state.admin_data.service_name[serviceName].system_name,
          country_code: this.$store.state.admin_data.service_name.url.country_code,
          tld: this.$store.state.admin_data.service_name.url.tld,
          status: 'sending',
          date: moment().format("YYYY-MM-DD HH:MM:SS"),
        }
        await Config.SENDMAIL_TARGET_COLLECTION.add(data);
      },
      modalClose() {
          this.$emit('modalclose')
      },
      changeModal(type){
        this.$emit(type);
      },
      validateForm() {
        this.$validator.validateAll().then((result) => {
          if (result) {
            this.placeOrderButtonPressed();
            return;
          }
        });
      }
    },
}
</script>

<style scoped>
.service-title{
  margin-bottom:6px;
}
.service-type {
  margin-bottom:8px;
}
.service-price {
  margin-top:8px;
}
.price-box {
  margin-top: 10px;
  padding-top: 10px;
  padding-bottom: 10px;
}
.payment-form .comment {
  font-size:0.8rem;
  margin-bottom:10px;

}
.payment-form .error {
   color:red;
}
.payment-form {
    max-width: 400px;
    margin: 20px auto;
    border: 2px solid #ececec;
    padding: 12px;
    margin-bottom: 8px;
}
.payment-form h5 {
    margin: 5px;
    padding: 0px;
    text-align:center;
    font-size: 1.2rem;
}
.payment-form h6 {
    margin-bottom: 5px;
    padding: 0px;
    text-align:center;
    font-size: 1.5rem;
}
.card-element {
    margin-top: 10px;
}

.place-order-button-block {
    margin: 5px 0;
    text-align:center;
}

button {
  background-color: #23D160;
  color: white;
  border-radius: 4px;
  font-size: 20px;
  /* font-weight: 600; */
  border: 0;
  box-shadow: none;
  padding: 8px 24px;
}
.ads-type-description{
  margin-bottom: 5px;
}
.page-link {
  margin-bottom: 20px;
}
</style>
