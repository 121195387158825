<template>
  <section class="section container">
    <div class="columns">
      <div class="column is-3">
        <Menu @getdoc='getPageId' @gettitle='getPageTitle'/>
      </div>
      <div class="column">
        <div v-if='doc'>
          <div class="pb-3">
            <h1 class="is-size-4 has-text-weight-bold py-1">広告内容の確認</h1>
            <p class="pt-3">広告の表示イメージと広告に使用する項目の入力状況を確認できます。</p>
            <p class="pt-3">広告は管理画面に入力されている項目を元に設定します。</p>

            <hr>
            <p class="pb-1">
              <span class="has-text-weight-bold	pb-2 is-size-4">広告の表示イメージ</span>
            </p>
            <br>
            <div>
              広告は管理画面に入力されている項目を元に、様々なパターンの組み合わせで表示されます。
            </div>
            <div>
              表示される広告のイメージ（一例）は下記のとおりです。
            </div>
            <br>
            <p style="margin-bottom: 5px;">
              <span class="has-text-weight-bold	pb-2">Google広告（検索）</span>
            </p>
            <div>
              <b-collapse
                class="card"
              >
                <div class="card-content" style="padding-top:10px;padding-left:5px;padding-right:5px;padding-bottom:10px;">
                    <div class="content">
                      <p class="is-size-7" style="margin:0px;"><b>広告</b>・jp.ilb.net/{{ doc['city'] }}/{{ doc['station'] }}</p>
                      <p class="is-size-6 has-text-link" style="margin:0px;">
                        {{ doc['ads_title'] ? doc['ads_title'] : doc['city']  + 'の' + doc['category'] }}
                      ｜{{ doc['service_keywords'] ? doc['service_keywords'][0] : ''}}
                      ｜{{ doc['service_keywords'] ? doc['service_keywords'][1] :'' }}</p>
                      <p class="is-size-7">{{ doc['catchphrase'] }}。{{ doc['station'] }}より{{ doc['access'] }}。</p>
                    </div>
                </div>
              </b-collapse>
            </div>
            <br>
            <p style="margin-bottom: 5px;">
              <span class="has-text-weight-bold">Google広告（ディスプレイ）</span>
            </p>
            <div class="card" style="width: 300px; height: 250px">
              <div class="card-image">
                <figure class="image">
                  <img :src="doc['feature'] ? doc['feature'][0]['image'] : ''" alt="image" style="object-fit: cover;">
                </figure>
              </div>
              <div class="card-content" style="padding-top: 5px;">
                <div class="media">
                  <div class="media-content">
                    <p class="has-text-weight-bold">{{ doc['feature'] ? doc['feature'][0]['title'] : ''}}</p>
                    <p class="is-size-7 has-text-grey">{{ doc['category']}}（{{ doc['city'] }}）{{ doc['catchphrase'] }}</p>
                    <div style="display:table;width: 250px;height: 150px;">
                      <p style="display:table-cell;text-align:left;font-size:14px;">
                        {{ doc['business_name'] }}
                      </p>
                      <p style="display:table-cell;text-align:right;" class="is-size-6 has-text-link">
                        詳細&ensp;>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <hr>
            <div class="py-3">
              <p class="pb-1">
                <span class="has-text-weight-bold	pb-2 is-size-4">広告に使用する項目</span>
              </p>
              <br>
              <div>
                各項目の入力状況は、下記よりチェックできます。
              </div>

              <div class="closing-rate-details-check-item">
                ※入力済みの項目は、チェック「<img src="@/assets/check-square.svg" style="opacity: 0.2;" />」と「<span style="text-decoration: line-through;">打ち消し線</span>」で表示されています。<br />
                例）<br />
                <img src="@/assets/square.svg" />未入力の項目<br />
                <img src="@/assets/check-square.svg" style="opacity: 0.2;" /><span class="item-entered">入力済みの項目</span>
              </div>
              <p v-if="checkAllPageDataItem" class="pt-2 has-text-danger">未入力の項目があるため広告が設定できません。未入力の項目を全て入力してください。</p>

              <hr>

              <div v-for="item in getIncreaseClosingRateItems" :key="item.title">
                <span class="has-text-weight-bold">{{ item.title }}</span><br>

                <div class="check-item closing-rate-details-check-item" style="margin-bottom:5px;">
                  <template v-for="(targetItem, index) in item.targetItems">
                    <div v-if="targetItem.name === 'ads_title'" :key="index">
                      <div v-if="!strCheck">
                        <img src="@/assets/square.svg" />
                        <span>{{ getPageDataItemName(targetItem.name) }}</span>
                        <p class="has-text-danger">半角31文字以上になっているため、規定の半角30文字（全角15文字）以内で入力してください。</p>
                      </div>
                    </div>
                    <div v-else :key="index">
                      <div v-if="checkPageDataItem(targetItem.name)">
                        <img src="@/assets/check-square.svg" style="opacity: 0.2;" />
                        <span class="item-entered">{{ getPageDataItemName(targetItem.name) }}</span>
                        <span class="item-entered" v-if="!targetItem.required">（任意）</span>
                      </div>
                      <div v-else>
                        <img src="@/assets/square.svg" />
                        <span>{{ getPageDataItemName(targetItem.name) }}</span>
                        <span v-if="!targetItem.required">（任意）</span>
                        <p v-if="targetItem.required" class="has-text-danger">広告設定に必須の項目です。入力してください。</p>
                      </div>
                    </div>
                  </template>
                </div>
                <div style="margin-bottom:20px;">
                  <a class="button is-link is-outlined mt-1" @click="$router.push('/?active_tab=' + item.tabNo)">{{ item.label }}</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import 'bulma/css/bulma.css'
import Menu from '@/components/Menu.vue'

export default {
  mixins: [Menu],
  components: {
    Menu,
  },
  data () {
    return {
      doc: {},
      title: {}
    }
  },
  computed: {
    getIncreaseClosingRateItems() {
      const items = []
      items.push(
        {
          id: 'required',
          label: '必須項目を入力・修正する',
          title: '１．必須項目',
          targetItems: [
            { name : 'category_group', required : true },
            { name : 'category', required : true },
            { name : 'ads_title', required : true },
            { name : 'business_name', required : true },
            { name : 'zip_code', required : true },
            { name : 'region', required : true },
            { name : 'city', required : true },
            { name : 'street_address', required : false },
            { name : 'catchphrase', required : true },
        ],
          tabNo: '0',
        }
      )
      items.push(
        {
          id: 'basicinfo',
          label: '基本情報を入力・修正する',
          title: '２．基本情報',
          targetItems: [
            { name : 'service_keywords_0', required : true },
            { name : 'service_keywords_1', required : true },
            { name : 'service_keywords_2', required : true },
            { name : 'service_keywords_3', required : true },
            { name : 'service_keywords_4', required : true },
            { name : 'service_keywords_5', required : false },
            { name : 'service_keywords_6', required : false },
            { name : 'service_keywords_7', required : false },
            { name : 'service_keywords_8', required : false },
            { name : 'service_keywords_9', required : false },
            { name : 'station', required : true },
            { name : 'access', required : true },
          ],
          tabNo: '1',
        }
      )
      items.push(
        {
          id: 'offer',
          label: 'オファーを入力・修正する',
          title: '３．オファー',
          targetItems: [
            { name : 'offer_catchphrase', required : false },
          ],
          tabNo: '2',
        }
      )
      items.push(
        {
          id: 'line',
          label: 'LINEを入力・修正する',
          title: '4．LINE',
          targetItems: [
            { name : 'line_title', required : false },
          ],
          tabNo: '3',
        }
      )
      items.push(
        {
          id: 'service',
          label: '提供サービスを入力・修正する',
          title: '5．提供サービス',
          targetItems: [
            { name : 'service0_image', required : false },
            { name : 'service1_image', required : false },
            { name : 'service2_image', required : false },
            { name : 'service3_image', required : false },
            { name : 'service4_image', required : false },
          ],
          tabNo: '4',
        }
      )
      items.push(
        {
          id: 'feature',
          label: '特長を入力・修正する',
          title: '6．特長',
          targetItems: [
            { name : 'feature0_title', required : true },
            { name : 'feature0_image', required : true },
            { name : 'feature1_title', required : true },
            { name : 'feature1_image', required : true },
            { name : 'feature2_title', required : true },
            { name : 'feature2_image', required : true },
            { name : 'feature3_title', required : true },
            { name : 'feature3_image', required : true },
            { name : 'feature4_title', required : true },
            { name : 'feature4_image', required : true },
          ],
          tabNo: '5',
        }
      )
      return items
    },
    checkAllPageDataItem() {
      let check = false
      outer:
      for (const item of this.getIncreaseClosingRateItems) {
        for (const targetItem of item.targetItems) {
          if (!this.checkPageDataItem(targetItem.name) && targetItem.required) {
            // ads_titleの時のみ
            if (targetItem.name === 'ads_title' && this.strCheck) {
              break outer
            }
            check = true
            break outer
          }
        }
      }
      return check;
    },
    strCheck () {
      if(this.strCount(this.doc['city'] + 'の' + this.doc['category']) > 30) {
        if(!this.doc["ads_title"] || this.strCount(this.doc["ads_title"]) > 30) {
          return false
        }
      }
      return true
    },
  },
  methods: {
    getPageId (value) {
      this.doc = value
    },
    getPageTitle (value) {
      this.title = value
    },
    checkPageDataItem(item) {
      if (
        this.doc[item] ||
        this.doc['feature'] ||
        this.doc['service'] ||
        this.doc['service_keywords']
      ) {
        if (
          item.match(/feature[0-9]/) ||
          item.match(/service[0-9]/)
        ) {
          const words = item.split('_')
          const firstWord = words[0].replace(/[0-9]/g, '')
          const firstNo = Number(words[0].replace(/[^0-9]/g, ''))
          return this.doc[firstWord][firstNo][words[1]]
        } else if (item.match(/service_keywords/)) {
          const no = Number(item.replace(/[^0-9]/g, ''))
          return this.doc['service_keywords'][no]
        } else if (Array.isArray(this.doc[item])) {
          return Object.keys(this.doc[item]).length
        } else {
          return this.doc[item]
        }
      }
    },
    getPageDataItemName(item) {
      if (
        item.match(/feature[0-9]/) ||
        item.match(/service[0-9]/)
      ) {
        const words = item.split('_')
        const firstWord = words[0].replace(/[0-9]/g, '')
        const firstNo = Number(words[0].replace(/[^0-9]/g, ''))
        if (this.title[firstWord]) {
          return this.title[firstWord][firstNo][words[1]]
        }
      } else if (item.match(/service_keywords/)) {
        const no = Number(item.replace(/[^0-9]/g, ''))
        if (this.title['service_keywords']) {
          return this.title['service_keywords'][no]
        }
      } else {
        return this.title[item]
      }
    },
    // ads_titleのカウント用
    strCount (str) {
      let len = 0;
      for (let i = 0; i < str.length; i++) {
        (str[i].match(/[ -~]/)) ? len += 1 : len += 2;
        }
      return len;
    },
  },
}
</script>

<style>
.card .card-image .image img {
  height: 150px;
}
</style>
